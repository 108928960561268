import React, { useState, useRef } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "./../../../CSS/Parameters.module.css";
import { ParameterType } from "./ParameterType";
import { NavBar } from "../../Navbar/Navbar";
import NewProject from "../../Navbar/NewProject";
import { useLocation } from "react-router-dom";
import { saveProject } from "../../API/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";

export const Parameters = () => {
  const notify = (message) => {
    toast.warn(message, {
      position: "top-center",
    });
  };

  const { state } = useLocation();
  const {
    ProjectTitle,
    ProjectType,
    SelectedCrop,
    SelectedReportType,
    StartingDate,
    DrawnGeojson,
    UploadedGeojson,
    totalArea,
    numberOfFarms,
  } = state;

  const [selectedParameters, setSelectedParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleRemove = (param) => {
    setSelectedParameters(selectedParameters.filter((item) => item !== param));
  };

  const parameters = [
    // {
    //   CropName: "Soil Moisture",
    //   Price: "100/ha",
    //   Description: "Essential for monitoring irrigation needs and efficiency.",
    // },
    {
      CropName: "Crop Growth",
      Price: "100/ha",
      Description: "Detailed analysis of crop development and growth stages.",
    },
    // {
    //   CropName: "Soil Nutrients",
    //   Price: "100/ha",
    //   Description: "Assessment of nutrient levels critical for crop health.",
    // },
    // {
    //   CropName: "Temperature",
    //   Price: "100",
    //   Description:
    //     "Tracks environmental temperature affecting crop conditions.",
    // },
    {
      CropName: "Crop Stress",
      Price: "100/ha",
      Description: "Identifies biotic factors causing stress to crops.",
    },
    {
      CropName: "Water Stress",
      Price: "100/ha",
      Description:
        "Detects insufficient water availability impacting crop yield.",
    },
  ];

  const navigate = useNavigate();

  const handleSaveProject = async () => {
    const geojson = UploadedGeojson || DrawnGeojson;
    const projectData = {
      project_title: ProjectTitle,
      project_type: ProjectType,
      starting_date: StartingDate,
      selected_crops: SelectedCrop,
      report_type: SelectedReportType,
      geojson: geojson,
      selected_parameters: selectedParameters,
      farm_area: totalArea.toFixed(2),
      number_of_farms: numberOfFarms,
      save_project: true,
    };

    try {
      const response = await saveProject(projectData);
      return response.data;
    } catch (error) {
      console.error("Error saving project:", error);
    }
  };

  const ToSubscribe = async () => {
    if (selectedParameters.length == 0) {
      notify("Please select a parameter to continue");
      return;
    }
    setLoading(true);

    try {
      const response = await handleSaveProject(); // Ensure the project is saved before navigating
      if (response.projectSaved) navigate("/HomePage");
      else {
        notify(response.msg);
      }
    } catch (error) {
      console.error("Error during subscription process:", error);
      // You can add additional error handling here if needed
    }
    setLoading(false);
  };

  const BackButtonToNavbar = () => {
    navigate("/AOI", {
      state: {
        ProjectTitle,
        ProjectType,
        SelectedCrop,
        SelectedReportType,
        StartingDate,
      },
    });
  };

  const handleParameterClick = (cropName) => {
    console.log(cropName);
    setSelectedParameters((prev) => {
      if (prev.includes(cropName)) {
        return prev.filter((name) => name !== cropName);
      } else {
        return [...prev, cropName];
      }
    });
  };

  const InHomePageFlag = false;

  return (
    <ThemeProvider theme={theme}>
      {" "}
      <ToastContainer autoClose={2000} />{" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Buttons*/}
        <NavBar
          InHomePageFlag={InHomePageFlag}
          BackButtonInNavbar={BackButtonToNavbar}
        />
        {/*New Project Flexbox*/}
        <NewProject CurrentPage="Parameters" />
        {/*Two left right containers*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "1em",
            pl: "3em",
            pr: "3em",
            gap: "2rem",
          }}
        >
          <Box className={styles.left_container}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "3rem",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  marginLeft: "2rem",
                  position: "relative",
                  fontWeight: "bold",
                  color: "#0F2C5A",
                  fontSize: "18px",
                }}
              >
                Select Parameters
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    width: "50%",
                    height: "4px", // Adjust the height as needed
                    bottom: "-5px", // Adjust the position as needed
                    left: "0",
                    backgroundColor: "#FFA700",
                  }}
                ></span>
              </span>
            </div>
            <Box
              sx={{
                ml: "2rem",
                fontWeight: "bold",
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedParameters.map((param) => (
                  <div key={param} className={styles.parameterChip}>
                    <div className={styles.iconPlaceholder}></div>
                    <span className={styles.parameterText}>{param}</span>
                    {/* <div
                      className={styles.closeButton}
                      onClick={() => handleRemove(param)}
                    >
                      <CloseIcon style={{ fontSize: "16px" }} />
                    </div> */}
                  </div>
                ))}
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                fontSize: "13px",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  ml: "2em",
                  display: "flex",
                  justifyContent: "space-between",
                  mr: "2em",
                }}
              >
                Total Parameters <Box>{selectedParameters.length}</Box>
              </Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  ml: "2em",
                  display: "flex",
                  justifyContent: "space-between",
                  mr: "2em",
                }}
              >
                <span> Total Area : </span>

                <span>{`${(totalArea / 10000).toFixed(2)} `} ha</span>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <Button
                onClick={ToSubscribe}
                style={{
                  boxShadow:
                    "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
                  border: "0px", // Set border color to white
                  color: "white",
                  background:
                    " linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
                  fontSize: "12px",
                  textTransform: "none",
                  fontFamily: "Red Hat Display",
                  width: "22vw",
                  height: "6vh",
                  borderRadius: "12px",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Saving Project Details{" "}
                    <span style={{ marginLeft: "4px", marginTop: "0.2rem" }}>
                      {" "}
                      <BeatLoader size={4} margin={2} color="white" />
                    </span>
                  </div>
                ) : (
                  "Finish"
                )}
              </Button>
            </Box>
          </Box>

          <Box className={styles.right_container}>
            {/*Top Row*/}

            {/*Parameter Type Boxes*/}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "2rem",
              }}
            >
              {parameters.map((param) => (
                <ParameterType
                  key={param.CropName}
                  CropName={param.CropName}
                  Price={param.Price}
                  Description={param.Description}
                  onClick={() => handleParameterClick(param.CropName)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </div>
      <CssBaseline />
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    background: {
      default: "rgba(247, 245, 243, 1)", // Set the background color here
    },
  },
});
