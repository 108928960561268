import { useEffect, useState } from "react";

const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_329_1877)">
        <path
          d="M5.33333 8H4C3.26467 8 2.66667 8.598 2.66667 9.33333V10.6667C2.66667 11.402 3.26467 12 4 12H5.33333C6.06867 12 6.66667 11.402 6.66667 10.6667V9.33333C6.66667 8.598 6.06867 8 5.33333 8ZM4 10.6667V9.33333H5.33333V10.6667H4ZM12.6667 1.33333H12V0.666667C12 0.298667 11.702 0 11.3333 0C10.9647 0 10.6667 0.298667 10.6667 0.666667V1.33333H5.33333V0.666667C5.33333 0.298667 5.03533 0 4.66667 0C4.298 0 4 0.298667 4 0.666667V1.33333H3.33333C1.49533 1.33333 0 2.82867 0 4.66667V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V4.66667C16 2.82867 14.5047 1.33333 12.6667 1.33333ZM3.33333 2.66667H12.6667C13.7693 2.66667 14.6667 3.564 14.6667 4.66667V5.33333H1.33333V4.66667C1.33333 3.564 2.23067 2.66667 3.33333 2.66667ZM12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V6.66667H14.6667V12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667Z"
          fill="#1F1F1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_329_1877">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LineIcon = () => (
  <svg
    width="303"
    height="2"
    viewBox="0 0 383 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1H383" stroke="none" stroke-opacity="0.2" stroke-width="0.4" />
  </svg>
);

const CalendarComponent = ({
  maxDateSelectable = 1,
  fetchedDateList,
  formattedDate,
  setFormattedDate,
  colorstring,
  startDate,
  endDate,
}) => {
  // console.log({ fetchedDateList });

  const [calendarData, setCalendarData] = useState([]);
  const [seletedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [seletedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [seletedDate, setSelectedDate] = useState(null);

  // console.log(colorstring);

  useEffect(() => {
    setFormattedDate(getCurrentSelectedDateComplete());
    // console.log(getCurrentSelectedDateComplete());
  }, [seletedDate]);

  const getCalendarArray = () => {
    const generateCalendarWithDays = (year, month) => {
      // Validate input
      if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
        return "Invalid input. Please provide a valid year and month.";
      }

      // Create a Date object for the given year and month
      const currentDate = new Date(year, month - 1, 1);

      // Get the number of days in the month
      const daysInMonth = new Date(year, month, 0).getDate();

      // Create an array to represent the calendar
      const calendar = [];

      // Create an array for days of the week
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      // Initialize the calendar with days of the week as the first row
      calendar.push(daysOfWeek);

      let row = [null, null, null, null, null, null, null];
      let day = 1;

      // Loop through the days of the month
      for (let i = 0; i < daysInMonth; i++) {
        const dayOfWeek = currentDate.getDay();
        row[dayOfWeek] = day;
        day++;

        // If it's the last day of the week, push the row and reset it
        if (dayOfWeek === 6 || i === daysInMonth - 1) {
          calendar.push(row);
          row = [null, null, null, null, null, null, null];
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return calendar;
    };
    // Example usage:
    const year = 2023;
    const month = 11; // November
    const calendar = generateCalendarWithDays(year, month);
    let tempCalendarData = [];
    // Display the calendar
    for (const row of calendar) {
      tempCalendarData.push(row.map((cell) => (cell === null ? "NA" : cell)));
    }
    setCalendarData(tempCalendarData);
  };

  useEffect(() => {
    getCalendarArray();
  }, []);

  const getCurrentSelectedDateComplete = () => {
    return !seletedDate
      ? null
      : `${
          seletedDate < 10 ? "0" + seletedDate : seletedDate
        }/${seletedMonth}/${seletedYear}`;
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "600",
          }}
        >
          {isHovered && (
            <div
              style={{
                position: "absolute",
                top: "17%",
                left: "20%",
                transform: "translateX(-50%)",
                backgroundColor: "white",
                padding: "5px",
                borderRadius: "4px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                zIndex: "1000",
                fontSize: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Date range selected was from {startDate} to {endDate}
            </div>
          )}

          <CalenderIcon />
          {colorstring === "one"
            ? "Pre Calender"
            : colorstring === "two"
            ? "Post Calender"
            : "Calender"}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <select
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            name="months"
            style={{
              borderRadius: "16px",
              padding: "6px 10px",
              border: "0",
              outline: "1px solid #808080",
              borderRight: "10px solid transparent",
              fontWeight: "600",
              backgroundColor: "transparent",
            }}
            value={seletedMonth}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
              setSelectedDate(null);
            }}
          >
            <option value="01">Jan</option>
            <option value="02">Feb</option>
            <option value="03">Mar</option>
            <option value="04">Apr</option>
            <option value="05">May</option>
            <option value="06">Jun</option>
            <option value="07">Jul</option>
            <option value="08">Aug</option>
            <option value="09">Sep</option>
            <option value="10">Oct</option>
            <option value="11">Nov</option>
            <option value="12">Dec</option>
          </select>

          <select
            name="years"
            style={{
              borderRadius: "16px",
              padding: "6px 10px",
              border: "0",
              outline: "1px solid #808080",
              borderRight: "10px solid transparent",
              fontWeight: "600",
              backgroundColor: "transparent",
            }}
            value={seletedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
              setSelectedDate(null);
            }}
          >
            {Array.from({ length: 10 }).map((_, idx) => (
              <option value={2023 + idx}>{2023 + idx}</option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ marginBottom: "-1rem" }}>
        <LineIcon />
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridTemplateRows: "repeat(6, 1fr)",
          gap: "0.2rem",
        }}
      >
        {calendarData.map((row, idx) => {
          return (
            <div
              key={idx}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "0.5rem",
                justifyItems: "center",
              }}
            >
              {row.map((cell, cellIdx) => {
                const isActive =
                  isNaN(cell) ||
                  fetchedDateList.includes(
                    `${seletedYear}-${seletedMonth}-${
                      cell < 10 ? "0" + cell : cell
                    }`
                  );
                return (
                  <div
                    key={cellIdx}
                    style={{
                      // width: "max-content",
                      display: "flex",
                      visibility: cell === "NA" ? "hidden" : "visible",
                      alignSelf: "center",
                      opacity: !isActive ? 0.3 : 1,
                      cursor: !isActive ? "default" : "pointer",
                      backgroundColor:
                        cell === seletedDate
                          ? colorstring === "one"
                            ? "#FFB831"
                            : "rgba(76, 84, 209, 1)"
                          : "transparent",
                      color: cell === seletedDate ? "white" : "black",
                      width: "34px",
                      height: "34px",
                      borderRadius: "99%",
                      textAlign: "center",
                      alignItems: "center",
                      fontSize: "12px",
                      justifyContent: "center",
                      fontWeight:
                        idx === 0 ? "600" : isNaN(cell) ? "normal" : "500",
                    }}
                    onClick={() => {
                      if (!isActive) return;
                      setSelectedDate(cell);
                    }}
                  >
                    {cell}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarComponent;
