import React, {
  useState,
  useRef,
  useEffect,
  useSyncExternalStore,
} from "react";
import "@fontsource/red-hat-display";
import styles from "../../../CSS/Insights.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AiTwotoneFunnelPlot } from "react-icons/ai";
import { TimeLineSVG } from "../../../Asset/SVG/AllSVG";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import { IoIosArrowDropdown } from "react-icons/io";
import ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../../CSS/App.css";
import { MultiSelect } from "react-multi-select-component";
import { graphDetail, StressWiseFarms } from "../../API/API";
import { useLocation, useNavigate } from "react-router-dom";
import Graph from "./Graph";
import ColorBar from "./Legend";
import { IoMdDownload } from "react-icons/io";
import {
  FaSeedling,
  FaMapMarkerAlt,
  FaLightbulb,
  FaExclamationTriangle,
} from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import Slider from "@mui/material/Slider";
import "rc-slider/assets/index.css";
import "./Circles.css";

const SetViewOnClick = ({ coords, map }) => {
  useEffect(() => {
    if (coords && map) {
      map.setView(coords, 19);
    }
  }, [coords, map]);

  return null;
};

const onFarmMouseOver = (x, layer) => {
  const popupContent = ReactDOMServer.renderToString(
    <PopupContent properties={x.properties} />
  );
  layer.bindPopup(popupContent).openPopup();
};

const onFarmMouseOut = (layer) => {
  layer.closePopup();
};

const PopupContent = ({ properties }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "200px",
        fontSize: "9px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        gap: "0.3rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            Plantation Date
          </div>

          <div>
            <strong>{properties.PLANT_DAY} </strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            RYOT CODE
          </div>

          <div>
            <strong>{properties.RYOT_CODE} </strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            RYOT NAME
          </div>

          <div>
            <strong>{properties.RYOT_NAME}</strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            Survey Date
          </div>

          <div>
            <strong>{properties.SURVEY_DAY}</strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            UNIT CODE
          </div>

          <div>
            <strong>{properties.UNIT_CODE}</strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            Variety
          </div>

          <div>
            <strong>{properties.VARIETY}</strong>
          </div>
        </div>
        {/* {dottedLineIcon} */}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.7em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            TYPE
          </div>

          <div>
            <strong>{properties.TYPE}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

const cleanAndParseTiffMinMax = (tiffString) => {
  const cleanedString = tiffString
    .replace(/\s+/g, "") // Remove all whitespace
    .replace(/(?<=\d)\.(?=,|\])/g, ".0") // Ensure numbers ending in a dot are followed by 0
    .replace(/\[,/g, "[0,") // Handle empty leading brackets like [,0]
    .replace(/,]/g, ",0]"); // Handle empty trailing brackets like ,0]

  try {
    return JSON.parse(cleanedString);
  } catch (error) {
    console.error("Error parsing TIFF Min Max:", error);
    return [];
  }
};

function GaugePlaceholder() {
  return (
    <div
      style={{
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        background: "linear-gradient(90deg, #0B9855 50%, #E5E5E5 50%)",
      }}
    />
  );
}

function getCategories(param) {
  if (param === "Water Stress") {
    return {
      categories: [
        "No Water Stress",
        "Medium Water Stress",
        "Severe Water Stress",
      ],
      label: "SWSI",
    };
  } else if (param === "Crop Stress") {
    return {
      categories: ["No Crop Stress", "Severe Crop Stress"],
      label: "REDSI",
    };
  } else {
    return {
      categories: [
        "Ideal Crop Growth",
        "Average Crop Growth",
        "Poor Crop Growth",
      ],
      label: "Yield (tons/ha)",
    };
  }
}

function getCategoryColor(category) {
  // Water Stress / Crop Stress / Crop Growth
  if (
    category === "No Water Stress" ||
    category === "No Crop Stress" ||
    category === "Ideal Crop Growth"
  ) {
    return "#10B981"; // Green
  } else if (
    category === "Medium Water Stress" ||
    category === "Average Crop Growth"
  ) {
    return "#FFCA7A"; // Orange
  } else if (
    category === "Severe Water Stress" ||
    category === "Severe Crop Stress" ||
    category === "Poor Crop Growth"
  ) {
    return "#EF4444"; // Red
  }
  // Default or unknown category
  return "grey";
}

const FarmStressListCard = ({
  selectedParam,
  stressWiseData,
  currentDate,
  setSelectedInference,
  setCoords,
  setGraphData,
  setSelected,
  setCurrentFarm,
  setSelectedFarmIds,
}) => {
  // 2) Handler for arrow clicks
  const handleArrowClick = async (uniqueFarmId) => {
    try {
      const data = await fetchGraphData(
        uniqueFarmId,
        currentDate,
        selectedParam
      );
      const newSelectedOptions = [
        { label: `Plot No. ${uniqueFarmId}`, value: uniqueFarmId },
      ];
      setSelectedFarmIds(uniqueFarmId);
      setCurrentFarm(uniqueFarmId);
      setSelected(newSelectedOptions);
      setSelectedInference(data.Inference);
      setCoords([data.centroid.latitude, data.centroid.longitude]);
      setGraphData(data.result);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  // 3) Determine categories & active tab
  const { categories, label } = getCategories(selectedParam);
  const [activeTab, setActiveTab] = useState(categories[0]);

  // 4) The data for the active tab
  const currentData = stressWiseData[activeTab] || [];

  return (
    <div
      style={{
        borderRadius: "12px",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
        fontFamily: "sans-serif",
        padding: "13px",
      }}
    >
      {/* Tabs Row */}
      <div style={{ display: "flex", marginBottom: "16px" }}>
        {categories.map((cat) => {
          // color for the active tab
          const tabColor = activeTab === cat ? getCategoryColor(cat) : "grey";
          return (
            <div
              key={cat}
              onClick={() => setActiveTab(cat)}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "24px",
                cursor: "pointer",
                color: tabColor, // color for text
                textDecoration: activeTab === cat ? "underline" : "none",
                textUnderlineOffset: activeTab === cat ? "4px" : "0px",
                fontWeight: activeTab === cat ? "600" : "400",
                textDecorationThickness: activeTab === cat ? "1.3px" : "0px",

                flexWrap: "nowrap",
              }}
              className={styles.Fontss}
            >
              <FaLightbulb
                // color the icon similarly
                color={tabColor}
                style={{ marginRight: "4px" }}
              />
              {cat}
            </div>
          );
        })}
      </div>

      {/* Label */}
      <div
        style={{
          display: "flex",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #F3F4F6",
          padding: "12px 0",
        }}
      >
        <div
          style={{
            width: "32px",
            textAlign: "center", // rank in the same color
            fontWeight: "600",
          }}
          className={styles.Fontss}
        >
          <FaMapMarkerAlt style={{ position: "relative", top: "2px" }} />
        </div>

        {/* Farm Name */}
        <div
          style={{
            flex: 1,
            fontWeight: "500",
          }}
          className={styles.Advisory}
        >
          Plot Number
        </div>

        <div
          style={{
            marginRight: "16px",
            color: "#6B7280",
            fontWeight: "400",
          }}
          className={styles.Advisory}
        >
          {label}
        </div>

        {/* The arrow, color-coded by active tab */}
        <div
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 18l6-6-6-6" />
          </svg>
        </div>
      </div>

      {/* Scrollable List */}
      <div
        style={{
          maxHeight: "240px",
          overflowY: "auto",
          paddingRight: "1.7rem",
          minHeight: "240px",
        }}
      >
        {currentData.map((farm, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #F3F4F6",
              padding: "12px 0",
            }}
          >
            {/* Rank */}
            <div
              style={{
                width: "32px",
                textAlign: "center",
                color: getCategoryColor(activeTab), // rank in the same color
                fontWeight: "600",
              }}
              className={styles.Fontss}
            >
              {index + 1}
            </div>

            {/* Farm Name */}
            <div
              style={{
                flex: 1,
                color: "#111827",
                fontWeight: "500",
              }}
              className={styles.Fontss}
            >
              {farm.unique_farm_id || "Unknown Farm"}
            </div>

            <div
              style={{
                marginRight: "16px",
                color: "#6B7280",
                fontWeight: "400",
              }}
              className={styles.Fontss}
            >
              {farm.result_details || "N/A"}
            </div>

            {/* The arrow, color-coded by active tab */}
            <div
              onClick={() => handleArrowClick(farm.unique_farm_id)}
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: getCategoryColor(activeTab),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 18l6-6-6-6" />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Dropdown = ({ options, selected, onChange }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <select
        style={{
          width: "auto", // Auto width adjusts based on content length
          appearance: "none",
          border: "none",
          background: "transparent",
          outline: "none",
          fontWeight: 400,
          fontSize: "11px",
          color: "#111827",
          padding: "10px 24px 10px 10px", // Right padding for the arrow space
          cursor: "pointer",
        }}
        value={selected}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>

      {/* The arrow icon is positioned absolutely within the container */}
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "6px", // Adjust as needed relative to padding
          display: "flex",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <IoIosArrowDropdown color="#8395A6" size={15} />
      </div>
    </div>
  );
};

// This function handles the API call to fetch graph details
const fetchGraphData = async (uniqueFarmId, date, parameter) => {
  try {
    const response = await graphDetail({
      unique_farm_id: uniqueFarmId,
      date,
      parameter,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching graph details:",
      error.response ? error.response.data.msg : error.message
    );
    throw error; // re-throw to let caller decide what to do
  }
};

const groupByDate = (data) => {
  return data.reduce((acc, item) => {
    const key = item.selected_date;

    // If key doesn't exist, create an empty array
    if (!acc[key]) {
      acc[key] = [];
    }

    // Push the current item to the correct parameter key
    acc[key].push(item);

    return acc;
  }, {}); // <- Make sure the reduce() result is returned
};

export const Parameters = ({
  FarmList,
  tiff_url,
  excel_url,
  tiff_min_max,
  geojsonData,
  selectedFarmId,
  tile_url,
  selected_date,
  selected_parameter,
  ParameterWiseList,
  Parameter_Data,
}) => {
  // project,
  // FarmList,
  // tiff_url,
  // excel_url,
  // result_id,
  // tiff_min_max,
  // geojson,
  // all_results_data,
  // tile_url,
  // marks,

  const { state } = useLocation();
  const navigate = useNavigate();
  const multiselectRef = useRef();
  const mapRef = useRef();

  const [tileUrl, setTileUrl] = useState(tile_url);
  const [currentFarm, setCurrentFarm] = useState("");
  const [selectedParam, setSelectedParam] = useState(selected_parameter);
  const [currentDate, setCurrentDate] = useState(selected_date);
  const [tiffMinMax, setTiffMinMax] = useState(
    cleanAndParseTiffMinMax(tiff_min_max)
  );
  const [tiffURL, setTiffURL] = useState(tiff_url);
  const [excelURL, setExcelURL] = useState(excel_url);
  const paramAbbreviations = {
    "Crop Growth": "Crop Yield (tons/ha)",
    "Crop Stress": "REDSI",
    "Water Stress": "SWSI",
  };

  // const [selectedIndex, setSelectedIndex] = useState(
  //   ParameterWiseList[selected_parameter].length - 1
  // );

  const [selectedIndex, setSelectedIndex] = useState(Parameter_Data.length - 1);
  console.log(Parameter_Data);
  const [sliderMarks, setMarks] = useState(
    Parameter_Data.map((item, index) => ({
      value: index, // Numeric value for the slider
      label: item.selected_date, // The date string
    }))
  );

  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedFarmIds, setSelectedFarmIds] = useState([]);

  const [graphData, setGraphData] = useState(null);
  const [coords, setCoords] = useState(null);

  const [layers] = useState({
    esriWorldImagery: true,
    googleMaps: false,
  });

  const [selectedInference, setSelectedInference] = useState("");
  const [stressWiseData, setStressWiseData] = useState({});

  const paramOptions = Object.keys(ParameterWiseList).map((key) => ({
    value: key,
    label: key,
  }));

  const [CurrentParmeterData, setCurrentParameterData] =
    useState(Parameter_Data);

  const [DateOptions, setDateOptions] = useState(
    Parameter_Data.map((key) => ({
      value: key.selected_date,
      label: key.selected_date,
    }))
  );

  // useEffect(() => {
  //   setCurrentDate(DateOptions[0].label);
  // }, [selectedParam]);

  const handleParamChange = async (para) => {
    const Parameter_Data = ParameterWiseList[para];

    const index = Parameter_Data.length - 1;
    const newDate = Parameter_Data[index].selected_date;

    const groupedData = groupByDate(Parameter_Data);
    const currentData = groupedData[newDate];

    // same as changing date from now

    if (currentFarm) {
      // fetch inference when currentFarm is non empty
      try {
        // Call the separate function to fetch graph data
        const data = await fetchGraphData(currentFarm, newDate, para);
        setSelectedInference(data.Inference);
        setCoords([data.centroid.latitude, data.centroid.longitude]);
        setGraphData(data.result);
      } catch (error) {
        // Error already logged in fetchGraphData; additional handling if needed.
      }
    }

    try {
      const response = await StressWiseFarms(newDate, para);
      // Or just the data
      setStressWiseData(response.data);
    } catch (error) {
      console.error("Error fetching stresswise farms:", error);
    }

    setCurrentDate(newDate);
    setTiffURL(currentData[0].tiff_url);
    setExcelURL(currentData[0].excel_url);
    setTiffMinMax(cleanAndParseTiffMinMax(currentData[0].tiff_min_max));
    setTileUrl(currentData[0].tile_url);
    setSelectedParam(para);
    setDateOptions(
      Parameter_Data.map((key) => ({
        value: key.selected_date,
        label: key.selected_date,
      }))
    );
    setMarks(
      Parameter_Data.map((item, index) => ({
        value: index, // Numeric value for the slider
        label: item.selected_date, // The date string
      }))
    );

    setSelectedIndex(Parameter_Data.length - 1);
    setCurrentParameterData(Parameter_Data);

    // change marks
    // change list of dates
    // choose a date to show shit on
    // assign a new date and asign that in both tile and currentdaTE
    // handleDateChange();
  };

  const handleDateChange = async (date) => {
    const index = DateOptions.findIndex((option) => option.value === date);

    const groupedData = groupByDate(CurrentParmeterData);
    const newDate = date;
    const currentData = groupedData[newDate];

    // fetch inference for that currentfarm
    // fetch stresswise data for that farm
    // set new tiff
    // set new excel
    //  set new tile
    // set new tiff min
    if (currentFarm) {
      // fetch inference when currentFarm is non empty
      try {
        // Call the separate function to fetch graph data
        const data = await fetchGraphData(currentFarm, newDate, selectedParam);
        setSelectedInference(data.Inference);
        setCoords([data.centroid.latitude, data.centroid.longitude]);
        setGraphData(data.result);
      } catch (error) {
        // Error already logged in fetchGraphData; additional handling if needed.
      }
    }

    try {
      const response = await StressWiseFarms(newDate, selectedParam);
      // Or just the data
      setStressWiseData(response.data);
    } catch (error) {
      console.error("Error fetching stresswise farms:", error);
    }
    setCurrentDate(date);
    setSelectedIndex(index);
    setTiffURL(currentData[0].tiff_url);
    setExcelURL(currentData[0].excel_url);
    setTiffMinMax(cleanAndParseTiffMinMax(currentData[0].tiff_min_max));
    setTileUrl(currentData[0].tile_url);
  };

  const onEachFarm = (x, layer) => {
    const isSelected = selectedFarmIds.includes(x.properties.FARM_ID);

    layer.setStyle({
      weight: 2,
      color: isSelected ? "red" : "#4169E1",
      fillOpacity: 0,
    });

    layer.on({
      mouseover: () => onFarmMouseOver(x, layer),
      // mouseout: () => onFarmMouseOut(layer),
      click: () => onFarmClick(x, layer),
    });
  };

  const onFarmClick = async (x, layer) => {
    const farmId = x.properties.FARM_ID;

    // If the farm is already selected, reset the style and exit early.
    if (selectedFarmIds.includes(farmId)) {
      layer.setStyle({ color: "#4169E1", fillOpacity: 0 });
      return;
    }

    // Attempt to fetch the graph details for the clicked farm.
    try {
      const data = await fetchGraphData(farmId, currentDate, selectedParam);
      setSelectedInference(data.Inference);
      // Optionally update other state (like graph data) if needed.
      setGraphData(data.result);
    } catch (error) {
      // Error is already logged in fetchGraphData.
      // Optionally, add error handling logic here.
    }

    // Update the selection state with the new farm.
    const newSelectedOptions = [{ label: `Plot No. ${farmId}`, value: farmId }];
    setSelected(newSelectedOptions);
    setSelectedFarmIds([farmId]); // assuming this is an array of selected IDs

    // Update the style of the layer to indicate selection.
    layer.setStyle({ color: "red" });
  };

  // This function handles the selection logic and then calls fetchGraphData
  const handleSelect = async (selectedItems) => {
    // Ensure there's at least one item selected
    if (selectedItems.length === 0) return;

    // Get the last selected item
    const lastSelectedItem = selectedItems[selectedItems.length - 1];

    // Update current farm immediately
    setCurrentFarm(lastSelectedItem.value);

    // If the item is already selected, do nothing
    if (selectedFarmIds.includes(lastSelectedItem.value)) return;

    // Update the selected items state
    setSelectedFarmIds([lastSelectedItem.value]);
    setSelected([lastSelectedItem]);

    // Get the unique farm ID from the selection
    const uniqueFarmId = lastSelectedItem.value;

    try {
      // Call the separate function to fetch graph data
      const data = await fetchGraphData(
        uniqueFarmId,
        currentDate,
        selectedParam
      );

      // Update your state based on the fetched data
      setSelectedInference(data.Inference);
      setCoords([data.centroid.latitude, data.centroid.longitude]);

      setGraphData(data.result);
    } catch (error) {
      // Error already logged in fetchGraphData; additional handling if needed.
    }
  };

  // Callback function to handle inference selection from Graph
  const handleInferenceSelect = (inference) => {
    setSelectedInference(inference);
  };

  const downloadFile = (temp) => {
    // Create a link element and simulate feta click to download the file
    const link = document.createElement("a");

    // Use the correct URLs for tiff and excel
    link.href = temp === "tiff" ? tiffURL : excelURL;

    // Set the correct file extension
    link.setAttribute(
      "download",
      `${temp}.${temp === "tiff" ? "tiff" : "xlsx"}`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  // const UpdateResult = (selectedTiff, index) => {
  //   setTiffMinMax(cleanAndParseTiffMinMax(selectedTiff.tiff_min_max));
  //   setTiffURL(selectedTiff.tiff_url);
  //   setTileUrl(selectedTiff.tile_url);
  //   setSelectedIndex(index);
  //   setCurrentDate(DateOptions[index].label);
  //   // setExcelURL(selectedTiff.excel_url);
  //   // setCurrentDate(selectedTiff.selected_date);
  //   // setTiffURL(selectedTiff.tiff_url);
  // };

  const handleSlider = async (event, value) => {
    const newDate = CurrentParmeterData[value].selected_date;

    const groupedData = groupByDate(CurrentParmeterData);

    const currentData = groupedData[newDate];

    // fetch inference for that currentfarm
    // fetch stresswise data for that farm
    // set new tiff
    // set new excel
    //  set new tile
    // set new tiff min
    if (currentFarm) {
      // fetch inference when currentFarm is non empty
      try {
        // Call the separate function to fetch graph data
        const data = await fetchGraphData(currentFarm, newDate, selectedParam);
        setSelectedInference(data.Inference);
        setCoords([data.centroid.latitude, data.centroid.longitude]);
        setGraphData(data.result);
      } catch (error) {
        // Error already logged in fetchGraphData; additional handling if needed.
      }
    }

    try {
      const response = await StressWiseFarms(newDate, selectedParam);
      // Or just the data
      setStressWiseData(response.data);
    } catch (error) {
      console.error("Error fetching stresswise farms:", error);
    }
    setSelectedIndex(value);
    setCurrentDate(newDate);
    setTiffURL(currentData[0].tiff_url);
    setExcelURL(currentData[0].excel_url);
    setTiffMinMax(cleanAndParseTiffMinMax(currentData[0].tiff_min_max));
    setTileUrl(currentData[0].tile_url);
  };

  const valueLabelFormat = (value) => {
    return sliderMarks[value]?.date || ""; // Show the date on hover
  };

  useEffect(() => {
    const newOptions = FarmList.features.map((feature) => ({
      label: `Plot No. ${feature.properties.FARM_ID}`,
      value: feature.properties.FARM_ID,
    }));

    setMultiSelectOptions(newOptions);
  }, []);

  useEffect(() => {
    if (selectedFarmId) {
      handleSelect([
        { label: `Plot No. ${selectedFarmId}`, value: selectedFarmId },
      ]);
    }
  }, [selectedFarmId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await StressWiseFarms(currentDate, selectedParam);
        // Or just the data
        setStressWiseData(response.data);
      } catch (error) {
        console.error("Error fetching stresswise farms:", error);
      }
    }

    // Call the function

    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "80%",
        padding: "10% 3rem 1rem 9%",
        gap: "1rem",
        height: "99%",
      }}
    >
      <div className={styles.left_container}>
        <div className={styles.multiSelectContainer}>
          <div style={{ width: "45%" }}>
            <MultiSelect
              options={multiSelectOptions}
              value={selected}
              onChange={handleSelect}
              hasSelectAll={false}
              placeholder="Your Custom Placeholder..."
              ref={multiselectRef}
              overrideStrings={{
                selectSomeItems: "Select Farm",
                allItemsAreSelected: "All Farms are selected.",
              }}
              className={styles.Fontss}
            />
          </div>

          <div
            onClick={() => downloadFile("tiff")}
            className={styles.downloadLabel}
            data-tooltip="Download GeoTIFF"
          >
            <IoMdDownload color="white" size={12} />
          </div>

          <div
            onClick={() => downloadFile("excel")}
            className={styles.downloadLabel}
            data-tooltip="Download Excel"
          >
            <IoMdDownload color="white" size={12} />
          </div>
        </div>

        {/* CARD 2: Parameter / Plot No. */}
        <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            paddingBottom: "12px",
          }}
          className={styles.Fontss}
        >
          {/* Parameter Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <FaSeedling color="#6B7280" />
              <span style={{ fontWeight: "bold" }}>Parameter</span>
            </div>

            <span
              style={{
                color: "#0B9855",
                fontWeight: 600,
              }}
            >
              <Dropdown
                options={paramOptions}
                selected={selectedParam}
                onChange={handleParamChange}
              />
            </span>
          </div>

          {/* Plot Number Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <FaMapMarkerAlt color="#6B7280" />
              <span style={{ fontWeight: "bold" }}>Plot no.</span>
            </div>
            <span style={{ color: "#6B7280" }}>{currentFarm}</span>
          </div>

          {/* Plot Number Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                paddingTop: "6px",
              }}
            >
              <FaSeedling color="#6B7280" />
              <span style={{ fontWeight: "bold" }}>Date</span>
            </div>

            <span
              style={{
                color: "#0B9855",
                fontWeight: 600,
              }}
            >
              <Dropdown
                options={DateOptions}
                selected={currentDate}
                onChange={handleDateChange}
              />
            </span>
          </div>
        </div>

        {/* CARD 3: Inference */}
        <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          className={styles.Fontss}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Title & Icon */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <FaLightbulb color="#6B7280" />
              <span style={{ fontWeight: "bold" }}>Inference</span>
            </div>

            <div>
              <div className="circle-container">
                <div className="outer-circle" />
                <div className="middle-circle" />
                <div className="inner-circle" />
              </div>
            </div>
          </div>

          {/* Status */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <span style={{ color: "#0B9855", fontWeight: 400 }}>
              {selectedInference}
            </span>
          </div>
        </div>

        {/* CARD 4: Advisory */}
        <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
          }}
          className={styles.Fontss}
        >
          {/* Title & Plot */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <FaExclamationTriangle color="#6B7280" />
              <span style={{ fontWeight: "bold" }}>Advisory</span>
            </div>
            <span
              style={{
                color: "#6B7280",

                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <AiTwotoneFunnelPlot
                style={{ position: "relative", top: "0px" }}
              />
              <span style={{ marginLeft: "2px" }}>{currentFarm}</span>
            </span>
          </div>

          {/* Content */}
          <div>
            <div
              style={{
                fontWeight: "bold",
                color: "#FBBF24",
                marginTop: "1rem",
              }}
            >
              No Stress Observed
            </div>
            <div
              style={{
                color: "#4B5563",
                marginTop: "0.25rem",
              }}
            >
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose
            </div>
          </div>
        </div>

        {/* CARD 8: Stress Wise Farms */}

        <FarmStressListCard
          selectedParam={selectedParam}
          stressWiseData={stressWiseData}
          currentDate={currentDate}
          setSelectedInference={setSelectedInference}
          setCoords={setCoords}
          setGraphData={setGraphData}
          setSelected={setSelected}
          setCurrentFarm={setCurrentFarm}
          setSelectedFarmIds={setSelectedFarmIds}
        />

        {/* CARD 5: Revenue */}
        <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
          }}
          className={styles.Fontss}
        >
          {/* Header Row */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <FaMoneyBillTrendUp color="#6B7280" />
            <span style={{ fontWeight: "bold" }} className={styles.Advisory}>
              Revenue
            </span>
          </div>

          {/* Content Row: left (revenues) + right (gauge) */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {/* Left Column */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5rem",
                minWidth: "180px",
              }}
            >
              {/* 34 Lac */}
              <div>
                <span
                  style={{
                    color: "#0B9855",
                    fontWeight: "bold",
                  }}
                  className={styles.Advisory}
                >
                  34 Lac
                </span>
                <span
                  style={{
                    color: "#0B9855",
                    marginLeft: "8px",
                  }}
                  className={styles.Fontss}
                >
                  ↑ 10%
                </span>
                <div style={{ color: "#6B7280" }}>Estimated Revenue</div>
              </div>

              {/* 38 Lac */}
              <div>
                <span
                  style={{
                    color: "#111827",
                    fontWeight: "bold",
                  }}
                  className={styles.Advisory}
                >
                  38 Lac
                </span>
                <div style={{ color: "#6B7280" }}>Ideal Revenue</div>
              </div>
            </div>

            {/* Right Column (Gauge Placeholder) */}
            {/* <GaugePlaceholder /> */}
          </div>
        </div>

        {/* CARD 6: Anticipated Yield */}
        <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
          }}
          className={styles.Advisory}
        >
          {/* Header Row */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <FaSeedling color="#6B7280" />
              <span
                style={{
                  fontWeight: "bold",

                  marginLeft: "4px",
                }}
              >
                Anticipated Yield
              </span>
            </div>
          </div>

          {/* Yields */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.6rem" }}
          >
            {/* Estimated Yield */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                <span
                  style={{
                    color: "#0B9855",
                    fontWeight: "bold",
                  }}
                  className={styles.Advisory}
                >
                  2000 ton/hec
                </span>
                <div style={{ color: "#6B7280" }} className={styles.Fontss}>
                  Estimated Yield
                </div>
              </div>

              <div style={{ color: "#0B9855" }}>10% more than last year</div>
            </div>

            {/* Dotted divider */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "1px",
                  border: "1px dashed #d1d5db",
                  margin: "20px 0",
                  width: "100%",
                }}
              />
            </div>

            {/* Ideal Yield */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                  className={styles.Advisory}
                >
                  1400 ton/hec
                </span>
                <div className={styles.Fontss} style={{ color: "grey" }}>
                  Ideal Yield
                </div>
              </div>
            </div>
            {/* Additional note */}
            {/* <div style={{ color: "#0B9855" }}>
              ↑ 10% more than ideal yield
            </div> */}
          </div>
        </div>

        {/* CARD 7: Stress Attribute */}
        {/* <div
          style={{
            background: "#F7F5F3",
            borderRadius: "18px",
            padding: "1rem",
            boxShadow:
              "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <FaExclamationTriangle color="#6B7280" />
            <span style={{ fontWeight: "bold",  }}>
              Stress Attribute
            </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.75rem",
            }}
          >
            <div style={{ fontSize: "10px", color: "#6B7280" }}>
              Stress type
            </div>
            <div
              style={{
                fontSize: "11px",
                color: "#0B9855",
                fontWeight: "bold",
              }}
            >
              Biotic
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "11px", color: "#6B7280" }}>
              Crop Stress Frequency
            </div>
            <div
              style={{
                fontSize: "11px",
                color: "#111827",
                fontWeight: "bold",
              }}
            >
              04
            </div>
          </div>
        </div> */}
      </div>

      <div className={styles.right_container}>
        <div className={styles.map_container}>
          <MapContainer
            center={[27.692498575871063, 80.1096433069251]} // long,lat of centroid of farm
            zoom={10}
            scrollWheelZoom={true}
            ref={mapRef}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
          >
            <div
              style={{
                position: "absolute",
                zIndex: "1000",
                bottom: "10px",
                left: "30%",
                background: "#F7F5F3",
                width: "23vw",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                display: "flex",
                padding: "10px 5px 10px 5px",
              }}
            >
              <div
                style={{ display: "flex", padding: "1em", paddingTop: "0.4em" }}
              >
                <TimeLineSVG />
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  paddingLeft: "1em",
                }}
              >
                <Slider
                  value={selectedIndex}
                  onChange={handleSlider}
                  marks={sliderMarks}
                  min={0}
                  max={sliderMarks.length - 1}
                  step={1}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => sliderMarks[value].label}
                  sx={{
                    "& .MuiSlider-track": { display: "none" },
                    "& .MuiSlider-rail": { background: "#C4C4C4" },
                    // All marks default to grey.
                    "& .MuiSlider-markLabel": {
                      display: "none",
                      margin: 0,
                      padding: 0,
                      height: 0,
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#4CB963",
                      width: 4,
                      height: 16,
                      borderRadius: 16,
                    },

                    "& .MuiSlider-thumb": {
                      width: 5,
                      height: 30,
                      borderRadius: 16,
                      backgroundColor: "#0B9855",
                      boxShadow: "none",
                      "&:hover, &.Mui-focusVisible, &.Mui-active": {
                        boxShadow: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>

            <SetViewOnClick coords={coords} map={mapRef.current} />
            <GeoJSON
              key={selectedFarmIds}
              data={geojsonData}
              onEachFeature={onEachFarm}
            />

            <LayersControl position="topright">
              <LayersControl.BaseLayer
                checked={layers.esriWorldImagery}
                name="Satellite"
              >
                <TileLayer
                  url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer
                checked={layers.googleMaps}
                name="Google Maps"
              >
                <TileLayer
                  url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key=$`}
                />
              </LayersControl.BaseLayer>

              <LayersControl.Overlay checked name="Custom Tiles">
                <LayerGroup>
                  <TileLayer
                    key={tileUrl}
                    url={tileUrl}
                    tms={false} // Set to true if your tiles use the TMS convention
                    minZoom={0}
                    maxZoom={18}
                    opacity={0.9}
                    zIndex={10000}
                  />
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
            <ColorBar
              colorRange={tiffMinMax}
              legendString={paramAbbreviations[selectedParam]}
            />
          </MapContainer>
        </div>

        <div style={{ display: "flex", marginTop: "3rem" }}>
          {" "}
          <div style={{ display: "flex" }}>
            <Graph
              graphData={graphData}
              Parameter={selectedParam}
              currentDate={currentDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
