import React, { useState, useCallback } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "@fontsource/red-hat-display";
import styles from "./../../../CSS/Details.module.css";
import { FiChevronDown } from "react-icons/fi";
import BasicDateCalendar from "./Calendar";
import { PlantationDateIcon } from "../../../Asset/SVG/AllSVG";
import "../../../CSS/App.css";
import dayjs from "dayjs";

const StartingDate = ({ onDateSelect }) => {
  const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(dayjs());

  const openCalendar = useCallback(() => {
    setOpenCalendarDialog(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setOpenCalendarDialog(false);
  }, []);

  const handleDateSelection = useCallback(
    (date) => {
      setSelectedDate(date);
      setCalendarDate(date);
      onDateSelect(date); // Pass selected date to parent component
      closeCalendar();
    },
    [closeCalendar, onDateSelect]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        fontFamily: "Red Hat Display",
      }}
    >
      <PlantationDateIcon />

      <Box onClick={openCalendar} className={styles.calender_container}>
        {selectedDate ? selectedDate.format("DD/MM/YYYY") : "Select Date"}
        <FiChevronDown />
      </Box>

      <Dialog open={openCalendarDialog} onClose={closeCalendar}>
        <DialogContent>
          <BasicDateCalendar
            onDateSelect={handleDateSelection}
            initialDate={calendarDate}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StartingDate;
