import React, { useState } from "react";
import styles from "../../CSS/Login.module.css";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AgriAiSVG,
  SignInSVG,
  ConfirmPasswordIconSVG,
  PasswordIconSVG,
  AtIconSVG,
  NameIconSVG,
} from "../../Asset/SVG/AllSVG";
import { useNavigate } from "react-router-dom";
import { register } from "../API/API";
import { fontSize } from "@mui/system";

const CreateAccount = () => {
  const navigate = useNavigate();
  const isHeightSm = useMediaQuery("(max-height:600px)");
  const isHeightMd = useMediaQuery("(max-height:800px)");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);

  const notify = () => {
    toast.info("Check your email to confirm your account", {
      position: "top-center",
      style: {
        fontSize: "14px", // Adjust font size here
      },
    });
  };

  const delayerFunc = () => {
    setTimeout(() => {
      SetlowerText("");
    }, "2000");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Basic format check
    if (email === "") {
      SetlowerText("Email cannot be empty!");
      delayerFunc();
      return false;
    }
    if (!emailRegex.test(email)) {
      SetlowerText("Incorrect Email Format");
      delayerFunc();
      return false;
    }

    // Check for consecutive dots in domain part
    if (email.includes("..")) {
      SetlowerText("Incorrect Email Format");
      delayerFunc();
      return false;
    }

    // Check for consecutive special characters
    if (/(\.\.|__|~~|-\-|\+\+|%$|#@)/.test(email)) {
      SetlowerText("Incorrect Email Format");
      delayerFunc();
      return false;
    }

    // Check for valid top-level domain (TLD)
    const tldRegex = /[a-zA-Z]{2,}$/;
    const tldMatch = email.match(tldRegex);
    if (!tldMatch) {
      SetlowerText("Incorrect Email Format");
      delayerFunc();
      return false;
    }

    return true;
  };

  const [submitClicked, SetSubmitClicked] = useState(false);
  const [lowerText, SetlowerText] = useState("");

  function validatePassword(password) {
    if (password.length < 8) {
      SetlowerText("Password must be at least 8 characters long.");
      delayerFunc();
      return false;
    }
    if (!/\d/.test(password)) {
      SetlowerText("Password must contain at least one digit.");
      delayerFunc();
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      SetlowerText("Password must contain at least one uppercase letter.");
      delayerFunc();
      return false;
    }
    if (!/[a-z]/.test(password)) {
      SetlowerText("Password must contain at least one lowercase letter.");
      delayerFunc();
      return false;
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
      SetlowerText(
        "Password must contain at least one special character (e.g., !, @, #)."
      );
      delayerFunc();
      return false;
    }
    return true;
  }

  const sequentialExecution = async () => {
    notify(); // Show toast notification
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for toast to auto-close
    GoToLogin(); // Redirect to the login page
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      SetlowerText("Please enter your name");
      delayerFunc();
      return;
    }

    if (!validateEmail(email)) return;

    if (password === "") {
      SetlowerText("Password can't be empty");
      delayerFunc();
      return;
    }

    const passwordCheck = validatePassword(password);
    if (!passwordCheck) return;

    if (password !== password2) {
      SetlowerText("Passwords do not match");
      delayerFunc();
      return;
    }

    {
      SetSubmitClicked(true);
      try {
        const responseData = await register(name, email, password); // Call the register function
        // Check if login was successful
        if (responseData.data.success) {
          sequentialExecution();
        } else {
          SetlowerText(responseData.data.msg);
        }
        SetSubmitClicked(false); // Reset the submit button state
        delayerFunc();
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  const GoToLogin = () => {
    navigate("/");
  };

  return (
    <div className={styles.background_container}>
      <div className={styles.Infra}>
        {" "}
        <AgriAiSVG color="white" />
      </div>
      <ToastContainer autoClose={2000} />
      <div className={styles.RightDiv}>
        <Paper
          elevation={3}
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 0.66)), linear-gradient(0deg, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0.23))",
            border: "1px solid rgba(255, 255, 255, 0.23)",
            width: "80%",
            borderRadius: "25px",
            backdropFilter: "blur(9px)",
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            paddingTop: "1em",
            alignItems: "center",
            justifyContent: "space-around",
            paddingRight: "0.9rem",
          }}
        >
          <div>
            <Box
              sx={{
                paddingTop: isHeightSm
                  ? "0.1rem"
                  : isHeightMd
                  ? "0.5rem"
                  : "1rem",
              }}
            >
              {" "}
              <SignInSVG />
            </Box>

            <Typography
              sx={{ fontSize: "12px", fontFamily: "Red Hat Display" }}
            >
              Already have an account ?{" "}
              <a
                onClick={GoToLogin}
                style={{
                  color: "rgba(210, 138, 0, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Sign In
              </a>
            </Typography>
          </div>

          <div style={{}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                mt: "1rem",
              }}
            >
              <form style={{ height: "60px" }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    fontWeight: "500",
                  }}
                >
                  {NameIconSVG}
                  <span style={{ verticalAlign: "super", color: "Red" }}>
                    *
                  </span>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                pt: "1rem",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <AtIconSVG />
                  <span style={{ verticalAlign: "super", color: "Red" }}>
                    *
                  </span>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "265px", // Adjust the width as needed
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                pt: "1.3em",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "15px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <PasswordIconSVG />
                      <span style={{ verticalAlign: "super", color: "Red" }}>
                        *
                      </span>
                    </div>
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                pt: "1.5em",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "15px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "0.3rem",
                        alignItems: "center",
                      }}
                    >
                      <ConfirmPasswordIconSVG />{" "}
                    </div>

                    <IconButton
                      onClick={handleTogglePasswordVisibility2}
                      sx={{ padding: "0" }}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  type={showPassword2 ? "text" : "password"}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />

                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <div
              style={{
                fontSize: "12px",
                color: lowerText ? "red" : "transparent",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {lowerText ? <div>{lowerText}</div> : <div>yess</div>}
            </div>
          </div>

          <Box
            style={{
              paddingTop: isHeightSm ? "0.5rem" : isHeightMd ? "1rem" : "2rem",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "1rem",
            }}
          >
            {" "}
            <Button
              onClick={handleSubmit}
              variant="contained"
              component="div"
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(72, 87, 99, 1)",
                height: "52px",
                textTransform: "none",
                textDecoration: "none", // Add underline
                padding: "2px 165px", // Adjust padding to make the button smaller
                borderRadius: "8px",
                background: "rgba(76, 84, 209, 1)",
                color: "white",
                width: "7%",
                whiteSpace: "nowrap",
              }}
            >
              {submitClicked ? "Signing In..." : "Sign In"}
            </Button>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Red Hat Display",
              fontWeight: "bold",
              pt: "2rem",
              pb: "1rem",
            }}
          >
            Powered by ARMS 4 AI
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default CreateAccount;
