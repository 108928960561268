import React, { useState, useRef } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "./../../../CSS/Subscribe.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { NavBar } from "../../Navbar/Navbar";
import {
  PaymentMethodTextSVG,
  BillingAdressTextSVG,
} from "../../../Asset/SVG/AllSVG";
import NewProject from "../../Navbar/NewProject";

export const Subscribe = () => {
  const navigate = useNavigate();
  const InHomePageFlag = false;
  const BackButtonToNavbar = () => {
    navigate("/Parameters");
  };
  return (
    <ThemeProvider theme={theme}>
      {" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Buttons*/}
        <NavBar
          InHomePageFlag={InHomePageFlag}
          BackButtonInNavbar={BackButtonToNavbar}
        />
        {/*New Project Flexbox*/}
        <NewProject CurrentPage="Subscribe" />
        {/* Billing Adress*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "1em",
            pl: "3em",
            pr: "3em",
            gap: "2rem",
          }}
        >
          <Box className={styles.left_container}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "flex-start",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <BillingAdressTextSVG />

              <Box className={styles.billing_address}>
                <AiOutlinePlus color="rgba(76, 185, 99, 1)" fontSize="1.5rem" />
                <span style={{ marginLeft: "1rem" }}>Add Billing Adress</span>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box className={styles.proceed_button}>Proceed</Box>
            </Box>
          </Box>

          <Box className={styles.right_container}>
            <PaymentMethodTextSVG />

            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: " 6rem",
                color: "rgba(128, 128, 128, 1)",
              }}
            >
              <Box>UPI</Box>

              <Box>Net Banking</Box>
              <Box>Debit/Credit Card</Box>
              <Box>Wallet</Box>
            </Typography>
          </Box>
        </Box>
      </div>
      <CssBaseline />
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    background: {
      default: "rgba(247, 245, 243, 1)", // Set the background color here
    },
  },
});
