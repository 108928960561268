import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine, // Import ReferenceLine
} from "recharts";
import { format, parse } from "date-fns";

export default function Graph({ graphData, Parameter, currentDate }) {
  if (!graphData || Object.keys(graphData).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          padding: "2rem",
          fontWeight: "bold",
          paddingLeft: "4.3rem",
        }}
      >
        Select a farm!
      </div>
    );
  }

  // Choose Y-axis label based on parameter
  const yAxisLabel =
    Parameter === "Crop Growth"
      ? "Crop Yield (ton/ha)"
      : Parameter === "Crop Stress"
      ? "REDSI"
      : "SWSI";

  // Define a domain for the Y-axis. Adjust as needed.
  const domainRange = [-1, 1];

  // Convert the graphData object to an array of { date, val } objects, then sort by date.
  const data = Object.entries(graphData)
    .map(([date, value]) => ({
      date,
      val: parseFloat(value),
    }))
    .sort((a, b) => {
      const parsedA = parse(a.date, "dd/MM/yyyy", new Date());
      const parsedB = parse(b.date, "dd/MM/yyyy", new Date());
      return parsedA - parsedB;
    });

  return (
    <div style={{ display: "flex", marginLeft: "4vw" }}>
      <AreaChart
        width={700}
        height={400}
        data={data}
        margin={{ top: 50, right: 30, left: 20, bottom: 50 }}
      >
        {/* Define gradient for area fill */}
        <defs>
          <linearGradient id="colorVal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#34C759" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#34C759" stopOpacity={0} />
          </linearGradient>
        </defs>

        <CartesianGrid stroke="#ccc" />

        <XAxis
          tickMargin={20}
          dataKey="date"
          label={{
            value: "Date",
            position: "insideBottom",
            offset: -30,
          }}
          tickFormatter={(tick) => {
            const parsedDate = parse(tick, "dd/MM/yyyy", new Date());
            return format(parsedDate, "dd MMM");
          }}
        />

        <YAxis
          tickMargin={10}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: "insideLeft",
            offset: -10,
          }}
          domain={domainRange}
        />

        <Tooltip
          formatter={(value) => [`${value}`, "Value"]}
          labelFormatter={(label) => `Date: ${label}`}
        />

        {/* Highlight the current date with a ReferenceLine */}
        {currentDate && (
          <ReferenceLine
            x={currentDate}
            stroke="#34C759" // Choose a highlight color (red in this case)
            strokeWidth={1}
          />
        )}

        <Area
          type="monotone"
          dataKey="val"
          stroke="#34C759"
          fill="url(#colorVal)"
          fillOpacity={1}
          dot={{ r: 4 }}
          activeDot={{ r: 6 }}
        />
      </AreaChart>
    </div>
  );
}
