import React, { useState } from "react";
import chroma from "chroma-js";
import PropTypes from "prop-types";
import styles from "../../../CSS/Insights.module.css";

const ColorBar = ({ colorRange, legendString }) => {
  const color = [
    "#440154", // dark purple
    "#482878",
    "#3E4989",
    "#31688E",
    "#26828E",
    "#1F9E89",
    "#35B779",
    "#6ECE58",
    "#B5DE2B",
    "#FDE725", // yellow-green
  ];

  const [discreteMode, setDiscreteMode] = useState(true);
  const [minValue, maxValue] = colorRange; // Destructure the colorRange to get min and max values
  const numColors = 6; // Number of color segments
  const numLabels = 6; // Number of labels we want (including min and max)

  const colorScale = chroma.scale(color).domain([minValue, maxValue]);

  // Generate colors for the legend
  const colors = Array.from({ length: numColors }, (_, index) => {
    const value = minValue + ((maxValue - minValue) * index) / (numColors - 1);
    return colorScale(value).hex();
  });

  const labelValues = Array.from({ length: numLabels }, (_, index) => {
    return minValue + (index * (maxValue - minValue)) / (numLabels - 1);
  });

  // const parseLegendQuantile = (quantileString) => {
  //   try {
  //     return JSON.parse(quantileString); // Attempt to parse as JSON
  //   } catch (error) {
  //     console.error("Invalid  format:", error);
  //     return []; // Return an empty array on failure
  //   }
  // };

  // const quantileLabels = parseLegendQuantile();
  // Generate labels for the legend (e.g., 0, 10, 20, 30, 40, 50)
  // const labelValues = discreteMode
  //   // ? Array.from({ length: numLabels }, (_, index) => {
  //   //     return minValue + (index * (maxValue - minValue)) / (numLabels - 1);
  //   //   })
  //   // : quantileLabels;

  // // Toggle mode when clicking the legend
  // const toggleMode = () => {
  //   setDiscreteMode(!discreteMode); // in discrete mode means evenly distributed,onclick it gives evenly
  // };

  return (
    <div
      className={styles.LegendToolTip}
      // data-tooltip={discreteMode ? "Equal Intervals" : "Quantile Spread"}
      data-tooltip={legendString}
      // onClick={toggleMode} // Toggle mode on click
      style={{
        backgroundColor: "#F7F5F3",
        zIndex: 2000,
        display: "flex",
        flexFlow: "column",
        position: "absolute",
        flexDirection: "row",
        left: 10,
        bottom: 10,
        fontFamily: "Arial, sans-serif",
        alignItems: "center",
        padding: "10px",
        height: "30vh", // Adjusted height for vertical layout
        width: "5vw", // Adjusted width
        borderRadius: "10px",
        cursor: "pointer", // Add pointer cursor to indicate interactivity
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)", // Subtle shadow for better contrast
        gap: "0.4rem",
      }}
      title="Click to change legend view"
    >
      <div
        style={{
          width: "65%", // Adjusted width for vertical layout
          height: "100%",
          display: "flex",
          flexDirection: "column", // Stack items vertically
          alignItems: "center",
          marginLeft: "0.3em",
        }}
      >
        {colors.map((color, index) => (
          <div
            key={index}
            style={{
              backgroundColor: color,
              flex: "1",
              width: "100%", // Fill the entire width
              margin: "0",
            }}
          ></div>
        ))}
        {/* {discreteMode ? (
          // Discrete mode: display individual color segments
          colors.map((color, index) => (
            <div
              key={index}
              style={{
                backgroundColor: color,
                flex: "1",
                width: "100%", // Fill the entire width
                margin: "0",
              }}
            ></div>
          ))
        ) : (
          // Continuous mode: display gradient
          <div
            style={{
              flex: "1",
              width: "100%",
              height: "100%",
              backgroundImage: `linear-gradient(to bottom, ${colors.join(
                ", "
              )})`, // Vertical gradient
            }}
          ></div>
        )} */}
      </div>

      <div className={styles.legend_labels}>
        {labelValues.map((value, index) => (
          <div key={index} className={styles.legend_label}>
            <span>{value.toFixed(2)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

ColorBar.propTypes = {
  colorRange: PropTypes.arrayOf(PropTypes.number).isRequired, // colorRange prop will be an array of 2 numbers (min and max)
  color: PropTypes.arrayOf(PropTypes.string), // color prop
};

export default ColorBar;
