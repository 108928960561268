import React, { useState, useRef, useEffect } from "react";
import { Button, Box, TextField } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "./../../../CSS/AOI.module.css";
import "leaflet/dist/leaflet.css";
import { CiSearch } from "react-icons/ci";
import { NavBar } from "../../Navbar/Navbar";
import { UploadIconSVG } from "../../../Asset/SVG/AllSVG";
import "../../../CSS/App.css";
import { area, bbox } from "@turf/turf";
import NewProject from "../../Navbar/NewProject";
import { MdDelete } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaExpandAlt } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../../CSS/App.css";
import Spreadsheet from "react-spreadsheet";
import { useLocation } from "react-router-dom";

const ExcelComp = ({ data }) => {
  return <Spreadsheet data={data} />;
};

export const AOI = () => {
  // const [GeojsonData, setGeojsonData] = useState(null);
  const navigate = useNavigate();

  const { state } = useLocation();
  const {
    ProjectTitle,
    ProjectType,
    SelectedCrop,
    SelectedReportType,
    StartingDate,
  } = state;

  const [location, setLocation] = useState("");
  const [coords, setCoords] = useState(null);
  const [geojsonDataMap, setGeojsonDataMap] = useState(new Map());
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  const [totalArea, setTotalArea] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [numberOfFarms, setNumberOfFarms] = useState([]);
  const [layers] = useState({
    openStreetMap: true,
    esriWorldImagery: false,
  });
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const InHomePageFlag = false;

  const notify = (message) => {
    toast.warn(message, {
      position: "top-center",
    });
  };

  const mapRef = useRef();

  const ToParameters = () => {
    if (uploadedFiles.length === 0) {
      notify("Please draw or upload a farm!");
      return;
    }

    navigate("/Parameters", {
      state: {
        ProjectTitle,
        ProjectType,
        SelectedCrop,
        SelectedReportType,
        StartingDate,
        DrawnGeojson: getDrawnGeojson(),
        UploadedGeojson: getUploadedGeojson(),
        totalArea,
        numberOfFarms,
      },
    });
  };

  const BackButtonToNavbar = () => {
    navigate("/Details");
  };

  const _onCreate = (e) => {
    const layer = e.layer;
    const newFeature = layer.toGeoJSON();

    let areaInHectares = area(newFeature) / 10000,
      displayArea;

    if (areaInHectares >= 1000) {
      console.log(areaInHectares);
      displayArea = (areaInHectares / 1000).toFixed(2) + " kha"; // kilo hectares
    } else {
      displayArea = areaInHectares.toFixed(2) + " ha"; // hectares
    }
    // Assign a unique ID to the feature for easy deletion
    newFeature.properties.id = Date.now();

    // Update the state with the new feature and its area
    setDrawnFeatures((prevFeatures) => [
      ...prevFeatures,
      { ...newFeature, displayArea },
    ]);

    // Update the total area
    setTotalArea((prevTotalArea) => prevTotalArea + area(newFeature));
  };

  const _onDelete = (id) => {
    const newFeatures = drawnFeatures.filter(
      (feature) => feature.properties.id !== id
    );

    // Calculate the new total area after deletion
    const newTotalArea = newFeatures.reduce(
      (sum, feature) => sum + feature.area,
      0
    );

    setDrawnFeatures(newFeatures);
    setTotalArea(newTotalArea);
  };

  const getDrawnGeojson = () => {
    const allGeoJson = {
      type: "FeatureCollection",
      features: drawnFeatures,
    };
    return JSON.stringify(allGeoJson, null, 2);
  };

  const getUploadedGeojson = () => {
    return Array.from(geojsonDataMap.values()).map(
      (geojsonData) => geojsonData
    );
  };

  const handleFileUpload = async (event) => {
    if (drawnFeatures.length) {
      notify("Please delete the drawn polygon first!");
      return;
    }

    const file = event.target.files[0]; // Get the first (and only) file

    if (!file) {
      // No file was selected, so just return (user may have canceled the upload)
      return;
    }

    if (
      file.type !== "application/json" &&
      !file.name.endsWith(".json") &&
      !file.name.endsWith(".geojson")
    ) {
      notify("Please upload a valid Geojson file!");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result;
      const jsonData = JSON.parse(fileContent);
      console.log(jsonData);
      const areaInSquareMeters = area(jsonData);
      const bboxarray = bbox(jsonData);

      let longitude = (bboxarray[0] + bboxarray[2]) / 2,
        latitude = (bboxarray[1] + bboxarray[3]) / 2,
        areaInHectares = areaInSquareMeters / 10000,
        displayArea;

      if (areaInHectares >= 1000) {
        displayArea = (areaInHectares / 1000).toFixed(2) + " kha"; // kilo hectares
      } else {
        displayArea = areaInHectares.toFixed(2) + " ha"; // hectares
      }

      // displayArea = (areaInSquareMeters / 10000).toFixed(2) + " ha";

      if (
        jsonData.type === "FeatureCollection" &&
        Array.isArray(jsonData.features)
      ) {
        setNumberOfFarms(jsonData.features.length);
      }

      if (geojsonDataMap.has(file.name)) {
        notify("Geojson already uploaded!");
        return;
      }

      setCoords([latitude, longitude]);

      setGeojsonDataMap((prevMap) => new Map(prevMap.set(file.name, jsonData)));

      setTotalArea((prevTotalArea) => prevTotalArea + areaInSquareMeters);

      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        {
          name: file.name,
          area: displayArea,
          data: jsonData, // Store the JSON data of the file
        },
      ]);
    };

    reader.readAsText(file); // Read the file content
  };

  const extractFields = (geojson) => {
    if (!geojson.features || !Array.isArray(geojson.features)) {
      return []; // Return an empty array if features don't exist or are not an array
    }

    const fields = [];
    geojson.features.forEach((feature) => {
      const properties = feature.properties;
      const field = {};
      for (const key in properties) {
        if (properties.hasOwnProperty(key)) {
          field[key] = properties[key];
        }
      }
      fields.push(field);
    });
    return fields;
  };

  const handleFileClick = (file) => {
    const extractedData = extractFields(file.data);
    setSelectedFileData(extractedData);
    setSelectedFileName(file.name);
    setIsDialogOpen(true);
  };

  const handleDeleteFile = (fileName) => {
    const jsonData = geojsonDataMap.get(fileName);
    const areaInSquareMeters = area(jsonData);

    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );

    setGeojsonDataMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(fileName);
      return newMap;
    });

    if (selectedFileName === fileName) {
      setSelectedFileName(null);
      setSelectedFileData(null);
      setIsDialogOpen(false);
    }
    setTotalArea((prevTotalArea) => prevTotalArea - areaInSquareMeters);
  };

  const transformDataForSpreadsheet = (data) => {
    if (data.length === 0) return [];

    // Extract headers dynamically from the first data entry
    const headers = Object.keys(data[0]);

    // Create header row with readOnly property
    const headerRow = headers.map((header) => ({
      value: header,
      readOnly: true,
    }));

    // Create rows with readOnly property
    const rows = data.map((row) =>
      headers.map((header) => ({ value: row[header], readOnly: true }))
    );

    return [headerRow].concat(rows);
  };

  const SetViewOnClick = ({ coords, map }) => {
    useEffect(() => {
      if (coords && map) {
        map.setView(coords, 14);
      }
    }, [coords, map]);

    return null;
  };

  const searchLocation = async () => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${location}&limit=1`
    );

    const data = await response.json();
    if (data[0]) {
      const lat = parseFloat(data[0].lat);
      const lon = parseFloat(data[0].lon);
      setCoords([lat, lon]);
    } else {
      alert("Location not found");
    }
  };

  const handleLocationSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    searchLocation(); // Trigger the onClick action
  };

  useEffect(() => {
    Array.from(geojsonDataMap.values()).map((geojsonData, index) =>
      console.log(geojsonData)
    );
  }, [geojsonDataMap]);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={2000} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Buttons*/}
        <NavBar
          InHomePageFlag={InHomePageFlag}
          BackButtonInNavbar={BackButtonToNavbar}
        />
        {/*New Project Flexbox*/}
        <NewProject CurrentPage="AOI" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "1em",
            pl: "3em",
            pr: "3em",
            gap: "2rem",
          }}
        >
          <Box className={styles.left_container}>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "3rem",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    marginLeft: "2rem",
                    position: "relative",
                    fontWeight: "bold",
                    color: "#0F2C5A",
                    fontSize: "18px",
                  }}
                >
                  Area of Interest
                  <span
                    style={{
                      content: '""',
                      position: "absolute",
                      width: "50%",
                      height: "4px", // Adjust the height as needed
                      bottom: "-5px", // Adjust the position as needed
                      left: "0",
                      backgroundColor: "#FFA700",
                    }}
                  ></span>
                </span>
                {`${(totalArea / 10000).toFixed(2)} ha`}
              </div>

              {/*Search Location*/}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontFamily: "Red Hat Display",
                  marginTop: "1.4rem",
                }}
              >
                <form onSubmit={handleLocationSubmit}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Search Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    InputLabelProps={{
                      style: {
                        fontFamily: "Red Hat Display",
                        color: "rgba(48, 48, 48, 0.47)",
                        fontSize: "12px",
                      },
                    }}
                    sx={{
                      "& input": {
                        fontFamily: "Red Hat Display",
                        width: "12vw",
                        height: "1vh",
                        paddingLeft: "20px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "50px", // Adjust the value to control the oval shape
                        boxShadow:
                          "1.625px 1.625px 3.25px 0px rgba(174, 174, 192, 0.2) inset, -1.625px -1.625px 1.625px 0px rgba(255, 255, 255, 0.7) inset, 2.4375px 2.4375px 4.875px 0px rgba(174, 174, 192, 0.4), -1.625px -1.625px 4.875px 0px rgba(255, 255, 255, 1)",
                        border: "2px solid rgba(255, 255, 255, 1) !important", // Set border color to white
                        "&:hover fieldset": {
                          borderColor: "rgba(240, 240, 243, 1)",
                        },
                        "& fieldset": {
                          borderColor: "rgba(240, 240, 243, 1)", // Set the default border color to white
                        },
                        "&:focus-within fieldset": {
                          borderColor: "white", // Set the focus border color to white
                        },
                      },
                    }}
                    InputProps={{
                      // Replace with your SVG icon component
                      endAdornment: <CiSearch />,
                    }}
                  />
                  <button type="submit" style={{ display: "none" }} />
                </form>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      id="file-upload"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <Button
                      component="span" // This prop makes the button act like a span, which is necessary inside a label
                      variant="contained"
                      sx={{
                        width: "95px", // Adjust the width of the button as needed
                        height: "6vh", // Adjust the height of the button as needed
                        borderRadius: "30px", // Makes it oval-shaped
                        fontFamily: "Red Hat Display",
                        display: "flex",
                        alignItems: "center", // Align items vertically centered
                        justifyContent: "center", // Align items horizontally centered
                        textTransform: "none",
                        background:
                          "linear-gradient(180deg, #FFEBD3 9.62%, #FFAD5B 86.54%)",
                        boxShadow:
                          "6px 6px 12px 0px rgba(174, 174, 174, 0.32),-6px -6px 8px 2px rgba(255, 255, 255, 0.7)",
                        transition: "border-color 0.3s",
                        fontWeight: "800",
                        fontSize: "11px",
                      }}
                    >
                      <UploadIconSVG />
                      <span style={{ marginLeft: "8px", fontSize: "11px" }}>
                        Upload
                      </span>
                    </Button>
                  </label>
                </div>
              </Box>
              <div>
                {/* <div className="uploaded-files-container">
                  {drawnFeatures.map((feature, index) => (
                    <div
                      key={feature.properties.id}
                      className="uploaded-file"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        marginBottom: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {feature.properties.name || `Polygon ${index + 1}`}
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        Area: {feature.area} ha
                      </span>
                      <button
                        onClick={() => _onDelete(feature.properties.id)}
                        style={{
                          border: "none",
                          background: "none",
                          marginLeft: "10px",
                        }}
                      >
                        <MdDelete size={16} color="red" />
                      </button>
                      <button
                        onClick={() => {
                        }}
                        style={{
                          border: "none",
                          background: "none",
                          marginLeft: "5px",
                        }}
                      >
                        <FaExpandAlt size={16} color="black" />
                      </button>
                    </div>
                  ))}
                </div> */}

                <div className="uploaded-files-container">
                  {uploadedFiles.map((file) => (
                    <div key={file.name} className="uploaded-file">
                      <span>
                        {file.name.length > 15
                          ? file.name.slice(0, 10) + "..."
                          : file.name}
                      </span>
                      <span>Area: {file.area}</span>
                      <button onClick={() => handleDeleteFile(file.name)}>
                        <MdDelete size={16} />
                      </button>
                      <button onClick={() => handleFileClick(file)}>
                        <FaExpandAlt size={16} color="black" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="excel-container">
                {" "}
                <Dialog
                  open={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle sx={{ fontFamily: "Red Hat Display" }}>
                    {selectedFileName}
                  </DialogTitle>
                  <DialogContent>
                    {selectedFileData && (
                      <ExcelComp
                        data={transformDataForSpreadsheet(selectedFileData)}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setIsDialogOpen(false)}
                      color="primary"
                      sx={{ fontFamily: "Red Hat Display" }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <Button
                onClick={ToParameters}
                style={{
                  boxShadow:
                    "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
                  border: "0px", // Set border color to white
                  color: "white",
                  background:
                    " linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
                  fontSize: "12px",
                  textTransform: "none",
                  fontFamily: "Red Hat Display",
                  width: "22vw",
                  height: "6vh",
                  borderRadius: "12px",
                }}
              >
                {" "}
                Continue
              </Button>
            </Box>
          </Box>

          <div className={styles.map_container}>
            <MapContainer
              center={[24.42, 46.58]} // long,lat of Delhi
              zoom={9}
              scrollWheelZoom={true}
              ref={mapRef}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
            >
              {/* <GeoJSON data={geojsonData} key={JSON.stringify(geojsonData)} /> */}

              {Array.from(geojsonDataMap.values()).map((geojsonData, index) => (
                <GeoJSON key={index} data={geojsonData} />
              ))}

              <FeatureGroup>
                {!uploadedFiles.length && (
                  <EditControl
                    position="bottomleft"
                    onCreated={_onCreate}
                    onDeleted={_onDelete}
                    draw={{
                      circle: true,
                      marker: false,
                      polyline: true,
                      rectangle: true,
                      circlemarker: false,
                      polygon: true,
                    }}
                    edit={{
                      remove: true,
                      edit: false,
                    }}
                  />
                )}
              </FeatureGroup>
              <LayersControl position="topright">
                <LayersControl.BaseLayer
                  checked={layers.openStreetMap}
                  name="Google Maps"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key=$`}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer
                  checked={layers.esriWorldImagery}
                  name="Satellite"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=$`}
                    // url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
            </MapContainer>
          </div>
        </Box>
        <SetViewOnClick coords={coords} map={mapRef.current} />
      </div>
      <CssBaseline />
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    background: {
      default: "rgba(247, 245, 243, 1)",
    },
  },
});
