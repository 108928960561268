import "./CSS/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import Login from "./Components/Login/Login";
import ForgotPass from "./Components/Login/ForgotPass";
import CreateAccount from "./Components/Login/CreateAccount";
import ConfirmEmail from "./Components/Login/ConfirmEmail";
import { ParameterSelection } from "./Components/ProjectAnalysis/ParameterSelection";
import HomePage from "./Components/ProjectDetails/HomePage/HomePage";
import { Details } from "./Components/ProjectDetails/Details/Details";
import { AOI } from "./Components/ProjectDetails/AOI/AOI";
import { Parameters } from "./Components/ProjectDetails/Parameters/Parameters";
import { Subscribe } from "./Components/ProjectDetails/Subscribe/Subscribe";
import { DateSelection } from "./Components/ProjectAnalysis/DateSelection";
import { Insights } from "./Components/InsightsFolder/Insights";
import EmailVerified from "./Components/Login/EmailVerified";
import ResetPassword from "./Components/Login/ResetPassword";
import { AuthProvider } from "././Components/AuthContext/AuthContext";
import ProtectedRoute from "./Components/AuthContext/ProtectedRoute";
import styles from "./CSS/HomePage.module.css"; // Import your styles
import useUserStore from "./Components/useUserStore";
import TimeBarMapBoxComponent from "./Components/InsightsFolder/ParametersFolder/TimeBarMapbox";

const App = () => {
  const initializeUserDetails = useUserStore(
    (state) => state.initializeUserDetails
  );

  useEffect(() => {
    initializeUserDetails(); // Initialize user details on app load
  }, [initializeUserDetails]);

  return (
    <Router>
      <AuthProvider>
        <Suspense
          fallback={
            <div className={styles.loader_container}>
              <div className={styles.loader}></div>
              <div className={styles.loader_text}>Loading...</div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/ForgotPass" element={<ForgotPass />} />
            <Route path="/CreateAccount" element={<CreateAccount />} />
            <Route path="/ConfirmEmail" element={<ConfirmEmail />} />
            <Route path="/reset_password/:token" element={<ResetPassword />} />
            <Route path="/EmailVerified" element={<EmailVerified />} />

            {/* Protected Routes */}
            <Route
              path="/HomePage"
              element={<ProtectedRoute element={<HomePage />} />}
            />
            <Route
              path="/ParameterSelection"
              element={<ProtectedRoute element={<ParameterSelection />} />}
            />
            <Route
              path="/DateSelection"
              element={<ProtectedRoute element={<DateSelection />} />}
            />
            <Route
              path="/Details"
              element={<ProtectedRoute element={<Details />} />}
            />
            <Route path="/AOI" element={<ProtectedRoute element={<AOI />} />} />
            <Route
              path="/Parameters"
              element={<ProtectedRoute element={<Parameters />} />}
            />
            <Route
              path="/Insights"
              element={<ProtectedRoute element={<Insights />} />}
            />
            <Route
              path="/Subscribe"
              element={<ProtectedRoute element={<Subscribe />} />}
            />
            <Route
              path="/TimeBar"
              element={<ProtectedRoute element={<TimeBarMapBoxComponent />} />}
            />
          </Routes>
        </Suspense>
      </AuthProvider>
    </Router>
  );
};

export default App;
