import React, { useState, useRef, useEffect } from "react";
import "@fontsource/red-hat-display";
import styles from "../../../CSS/Summary.module.css";
import { CiCalendarDate } from "react-icons/ci";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FaHandHoldingWater, FaRegHourglass } from "react-icons/fa";
import GaugeChart from "react-gauge-chart";
import "react-toastify/dist/ReactToastify.css";
import { PiCactus } from "react-icons/pi";
import { ImLeaf } from "react-icons/im";
import {
  StressSVG,
  Line2SVG,
  LineFarmListSVG,
} from "../../../Asset/SVG/AllSVG";
import { Gauge } from "react-circular-gauge";
import { useLocation, useNavigate } from "react-router-dom";
import wheatImage from "../../../Asset/Images/Wheat.png";
import { fetchInferenceData, datesAnalysed } from "../../API/API";

function getCategories(param) {
  if (param === "Water Stress") {
    return {
      categories: [
        "No Water Stress",
        "Medium Water Stress",
        "Severe Water Stress",
      ],
      label: "SWSI",
    };
  } else if (param === "Crop Stress") {
    return {
      categories: ["No Crop Stress", "Severe Crop Stress"],
      label: "REDSI",
    };
  } else {
    return {
      categories: [
        "Ideal Crop Growth",
        "Average Crop Growth",
        "Poor Crop Growth",
      ],
      label: "Yield (tons/ha)",
    };
  }
}

const RevenueCard = () => {
  return (
    <div
      style={{
        width: "36%",
        borderRadius: "12px",
        padding: "16px",
        background: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "90%",
      }}
    >
      <div
        style={{
          top: 0,
          color: "grey",
          color: "Black",
          padding: "4px 6px",
          borderRadius: "8px",
          fontWeight: "bold",

          display: "flex",
          justifyContent: "flex-start",
        }}
        className={styles.Advisory}
      >
        {" "}
        Revenue Prediction
      </div>
      {/* Gauge Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
        className={styles.Revenue_Design}
      >
        {" "}
        <GaugeChart
          id="gauge-chart1"
          style={{ width: "53%" }}
          nrOfLevels={20} // Number of "segments" in the arc
          colors={["#F53424", "#FFA500"]} // Transition from red to orange
          arcWidth={0.4} // Thickness of the arc
          arcPadding={0.02} // Spacing between segments
          cornerRadius={3} // Rounded corners
          percent={0.75} // 75% fill
          hideText={true} // Hide the default text
        />
      </div>

      {/* Values Row */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "-1px",
        }}
        className={styles.Advisory}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ fontWeight: "700", color: "#10B981" }}>34 Lac</div>
          <div style={{ color: "grey" }}>Estimated Revenue</div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontWeight: "700", color: "#111827" }}>38 Lac</div>
          <div style={{ color: "grey" }}>Ideal Revenue</div>
        </div>
      </div>
    </div>
  );
};

const YieldCard = () => {
  return (
    <div
      style={{
        width: "34%",
        borderRadius: "12px",
        paddingLeft: "10px",
        background: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
        fontFamily: "sans-serif",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "2em",
        height: "90%",
      }}
    >
      <div
        style={{
          color: "grey",
          color: "Black",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "15px",
          marginLeft: "15px",
        }}
        className={styles.Advisory}
      >
        {" "}
        Anticipated Yield
      </div>

      <div
        style={{
          fontFamily: "sans-serif",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Left side text */}
        <div style={{ width: "50%" }}>
          {/* Estimated Yield */}
          <div
            style={{
              fontWeight: "700",
              color: "#10B981",
            }}
            className={styles.Yield_Number}
          >
            2000 ton/ha
          </div>
          <div
            style={{ color: "grey", marginBottom: "12px" }}
            className={styles.Yield_Text}
          >
            Estimated Yield
          </div>

          {/* Ideal Yield */}
          <div
            style={{
              fontWeight: "700",
              color: "#111827",
              marginBottom: "4px",
            }}
            className={styles.Yield_Number}
          >
            1400 ton/ha
          </div>
          <div style={{ color: "grey" }} className={styles.Yield_Text}>
            Ideal Yield
          </div>
        </div>

        {/* Right side gauge */}
        {/* <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            style={{ overflow: "visible" }}
          >
        
            <circle
              cx="60"
              cy="60"
              r="50"
              fill="none"
              stroke="#E5E7EB"
              strokeWidth="15"
            />

           
            <circle
              cx="60"
              cy="60"
              r="50"
              fill="none"
              stroke="#10B981"
              strokeWidth="15"
              strokeDasharray="314"
              strokeDashoffset="157"
              strokeLinecap="round"
              transform="rotate(-90 60 60)"
              
            />
          </svg>
        </div> */}

        <div style={{ width: "60%", height: "40%" }}>
          <Gauge
            value={45}
            minValue={0}
            maxValue={100}
            startAngle={0} // Top (12 o'clock) down to
            endAngle={360}
            arcColor="#0B9855" // Green arc color
            arcWidth={0.4}
            trackColor="#E5E5E5" // Gray background for unfilled portion
            renderValue={() => null}
            trackWidth={0.4}
          />
        </div>
      </div>
    </div>
  );
};

const AdvisoryCard = ({ selected_date }) => {
  // Example data
  const advisoryItems = [
    {
      title: "Irrigation",
      date: "Sep 2, 2025",
      description:
        "Various versions have evolved over the years, sometimes by accident, sometimes on purpose",
    },
    {
      title: "Production",
      date: "Sep 2, 2025",
      description:
        "Various versions have evolved over the years, sometimes by accident, sometimes on purpose",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        width: "26%",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
        position: "relative",
        padding: "0em 1em",
        fontFamily: "sans-serif",
        flexDirection: "row",
        background: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
      }}
    >
      {/* Green "Advisory" pill in top-left corner */}
      <div
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
          backgroundColor: "#10B981",
          color: "#FFFFFF",
          borderRadius: "8px",
          padding: "4px 12px",
          fontWeight: "500",
        }}
        className={styles.Advisory}
      >
        Advisory
      </div>

      {/* Content list, spaced down so it doesn't overlap the pill */}
      <div className={styles.scrollable_list_advisory}>
        {advisoryItems.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: "1.3em",
              position: "relative",
              paddingLeft: "1em",
            }}
          >
            {/* Green bullet on the left */}
            <div
              style={{
                position: "absolute",
                left: 0,
                top: "5px",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                backgroundColor: "#10B981",
              }}
            />

            {/* Title + Date row */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  color: "#111827",
                }}
              >
                {item.title}
              </div>
              <div style={{ color: "grey" }}> {selected_date}</div>
            </div>

            {/* Description */}
            <div style={{ color: "grey" }}>{item.description}</div>
          </div>
        ))}
      </div>

      {/* Vertical scroll "handle" on the right */}
      {/* <div
        style={{
          position: "absolute",
          top: "50%",
          right: "12px",
          transform: "translateY(-50%)",
          width: "1px",
          height: "80px",
          backgroundColor: "#E5E7EB",
        }}
      >
      
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-5px",
            transform: "translateY(-50%)",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#FFFFFF",
            border: "2px solid #10B981",
          }}
        />
      </div> */}
    </div>
  );
};

const StressCard = ({
  selected_date,
  selected_parameter,
  categories,
  stressCountObj,
}) => {
  return (
    <div
      style={{
        width: "40%",
        border: "1px solid #e5e7eb",
        borderRadius: "12px",
        backgroundColor: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
        fontFamily: "sans-serif",
        height: "90%",
        marginBottom: "2em",
      }}
      className={styles.Fontss}
    >
      {/* Top section: Pill + Main Value */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
            paddingTop: "1em",
            paddingLeft: "1em",
          }}
        >
          <div
            style={{
              backgroundColor: "#0F9C5D",
              color: "white",
              padding: "8px 10px",
              borderRadius: "14px",
              fontWeight: "500",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            {selected_parameter}
          </div>
          <div
            style={{
              fontWeight: "700",
              color: "#111827",
              background: "none",
              marginLeft: "10px",
            }}
          >
            {selected_date}
          </div>
        </div>

        {/* Chart + Percentage */}
        <div
          style={{
            position: "relative",
            paddingRight: "2em",
            paddingTop: "1em",
          }}
        >
          {/* <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "grey",
              color: "#10B981",
              padding: "4px 8px",
              borderRadius: "8px",
              marginRight: "12px",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            234%
          </div> */}
          {/* Example wave chart as an SVG path */}
          <div style={{}}>
            {" "}
            <StressSVG />
          </div>
        </div>
      </div>

      {/* Dotted divider */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "1px",
            border: "1px dashed #d1d5db",
            marginTop: "2.5vh",
            width: "100%",
            marginBottom: "2vh",
          }}
        />
      </div>

      {/* Bottom stats */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
        className={styles.bottom_stats}
      >
        {categories.map((cat, index) => {
          // Look up the count from your stressCountObj.
          // Using trim() in case of extra whitespace.
          const count = stressCountObj[cat.trim()] || 0;
          return (
            <React.Fragment key={index}>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    fontWeight: "700",
                    color: "#111827",
                  }}
                >
                  {count}
                </div>
                <div style={{ color: "#303030" }}>Farms</div>
                <div
                  style={{
                    color: "#303030",
                    fontWeight: "bold",
                  }}
                >
                  {cat}
                </div>
              </div>
              {/* Insert a divider between items if not the last item */}
              {index < categories.length - 1 && <Line2SVG />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const ParameterLegend = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "16px",
      }}
      className={styles.Fontss}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "16px" }}
      >
        <div
          style={{
            width: "9px",
            height: "9px",
            backgroundColor: "#10B981",
            marginRight: "6px",
            borderRadius: "50%",
          }}
        />
        <span style={{ color: "#374151" }}>Crop Stress</span>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "16px" }}
      >
        <div
          style={{
            width: "9px",
            height: "9px",
            backgroundColor: "skyblue",
            marginRight: "6px",
            borderRadius: "50%",
          }}
        />
        <span style={{ color: "#374151" }}>Water Stress</span>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "9px",
            height: "9px",
            backgroundColor: "#F59E0B",
            marginRight: "6px",
            borderRadius: "50%",
          }}
        />
        <span style={{ color: "#374151" }}>Crop Growth</span>
      </div>
    </div>
  );
};

const FarmStressChartCard = ({ worstFarmsData, bestFarmsData }) => {
  const [activeTab, setActiveTab] = useState("worst");

  // Decide which data to show based on toggle
  const chartData = activeTab === "worst" ? worstFarmsData : bestFarmsData;

  return (
    <div
      style={{
        width: "43%",
        borderRadius: "12px",
        padding: "13px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        fontFamily: "sans-serif",
        background: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
        fontSize: "9.4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {/* Toggle row: Worst Farms / Best Farms */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
          fontSize: "9.4px",
        }}
      >
        {/* Worst Farms Toggle */}
        <div
          onClick={() => setActiveTab("worst")}
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "16px",
            cursor: "pointer",
            color: activeTab === "worst" ? "#EF4444" : "grey", // red if active, gray otherwise
            textDecoration: activeTab === "worst" ? "underline" : "none",
            textUnderlineOffset: activeTab === "worst" ? "4px" : "0px",
            fontWeight: activeTab === "worst" ? "600" : "400",
            textDecorationThickness: activeTab === "worst" ? "1.3px" : "0px",
          }}
        >
          {/* Example icon for "worst" */}
          <PiCactus size={12} />
          <span style={{ marginLeft: "2px" }} className={styles.Fontss}>
            {" "}
            Worst Farms
          </span>
        </div>

        {/* Best Farms Toggle */}
        <div
          onClick={() => setActiveTab("best")}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: activeTab === "best" ? "#10B981" : "grey",
            textDecoration: activeTab === "best" ? "underline" : "none",
            textUnderlineOffset: activeTab === "best" ? "4px" : "0px",
            fontWeight: activeTab === "best" ? "600" : "400",
            textDecorationThickness: activeTab === "best" ? "1.3px" : "0px",
          }}
        >
          <ImLeaf size={11} />
          <span style={{ marginLeft: "2px" }} className={styles.Fontss}>
            {" "}
            Best Farms
          </span>
        </div>
      </div>

      {/* Bar Chart */}
      <div
        style={{
          width: "100%",
          height: "62%",
          fontSize: "9.5px",
          marginTop: "2.2em",
        }}
      >
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
            barGap={1}
          >
            {/* Remove or comment out CartesianGrid to remove dotted lines entirely:
                <CartesianGrid strokeDasharray="3 3" />
               If you want no grid lines at all, just omit it: 
            */}

            {/* <CartesianGrid strokeDasharray="none" stroke="none" /> */}

            {/*  */}
            <XAxis
              dataKey="name"
              tick={{
                fill: "#374151", // Dark gray shade
                fontSize: 10.2,
                fontWeight: 500,
              }}
              tickMargin={10}
            />
            <YAxis hide={true} />

            <Tooltip />

            <Bar
              dataKey="CropStress"
              fill="#10B981"
              barSize={7}
              radius={[8, 8, 8, 8]}
            />
            <Bar
              dataKey="WaterStress"
              fill="skyblue"
              barSize={7}
              radius={[8, 8, 8, 8]}
            />
            <Bar
              dataKey="CropGrowth"
              fill="#F59E0B"
              barSize={7}
              radius={[8, 8, 8, 8]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Manual legend row (optional) 
          If you prefer your own legend at the bottom, 
          comment out <Legend/> in the chart and use something like:
      */}

      <ParameterLegend />
    </div>
  );
};

const FarmStressListCard = ({ worstFarms, bestFarms, onSelectFarm }) => {
  // Toggle state: "worst" (Under Stress) or "best" (Best Farms)
  const [activeTab, setActiveTab] = useState("worst");

  // Decide which list to show
  const currentList = activeTab === "worst" ? worstFarms : bestFarms;

  return (
    <div
      style={{
        width: "25%",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
        fontFamily: "sans-serif",
        padding: "16px",
        position: "relative", // allows a custom scroll handle if needed
        fontSize: "11.4px",
        background: "rgba(247, 245, 243, 1)",
        boxShadow:
          "-8px -8px 24px 0px #FFFFFF, 8px 8px 24px 0px rgba(174, 174, 192, 0.24)",
      }}
    >
      {/* Toggle Row */}
      <div style={{ display: "flex", marginBottom: "16px" }}>
        {/* "Under Stress" Toggle */}
        <div
          onClick={() => setActiveTab("worst")}
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "16px",
            cursor: "pointer",
            color: activeTab === "worst" ? "#EF4444" : "grey", // red if active, gray otherwise
            textDecoration: activeTab === "worst" ? "underline" : "none",
            textUnderlineOffset: activeTab === "worst" ? "4px" : "0px",
            fontWeight: activeTab === "worst" ? "bold" : "400",
            textDecorationThickness: activeTab === "worst" ? "1.3px" : "0px",
          }}
        >
          {/* Example icon for "worst" */}
          <PiCactus size={12} />
          <span style={{ marginLeft: "2px" }} className={styles.Fontss}>
            {" "}
            Worst Farms
          </span>
        </div>

        {/* "Best Farms" Toggle */}
        <div
          onClick={() => setActiveTab("best")}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: activeTab === "best" ? "#10B981" : "grey",
            textDecoration: activeTab === "best" ? "underline" : "none",
            textUnderlineOffset: activeTab === "best" ? "4px" : "0px",
            fontWeight: activeTab === "best" ? "600" : "400",
            textDecorationThickness: activeTab === "best" ? "1.3px" : "0px",
          }}
        >
          <ImLeaf size={11} />
          <span style={{ marginLeft: "2px" }} className={styles.Fontss}>
            {" "}
            Best Farms
          </span>
        </div>
      </div>

      {/* Scrollable List */}
      <div className={styles.scrollable_list}>
        {currentList.map((farm) => (
          <>
            {" "}
            <div
              key={farm.rank}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "4px 0px",
              }}
            >
              {/* Rank (in red) */}
              {/* RANK (conditionally colored) */}
              <div
                style={{
                  width: "32px",
                  textAlign: "center",
                  fontWeight: "600",
                  // Choose red (#EF4444) for worst, green (#10B981) for best
                  color: activeTab === "worst" ? "#EF4444" : "#10B981",
                }}
              >
                {farm.rank}
              </div>

              {/* Farm Name (flex:1 to grow) */}
              <div
                style={{
                  flex: 1,
                  color: "#111827",
                  fontWeight: "1000",
                }}
              >
                Plot No. {farm.name}
              </div>

              {/* CIRCULAR ICON*/}
              <svg
                onClick={() => onSelectFarm(farm.name)}
                cursor="pointer"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill={activeTab === "worst" ? "#EF4444" : "#10B981"}
                />
                <g clip-path="url(#clip0_3636_1236)">
                  <path
                    d="M14.6668 8H11.6668C11.4824 8 11.3334 8.14933 11.3334 8.33333C11.3334 8.51733 11.4824 8.66667 11.6668 8.66667H14.6668C14.7264 8.66667 14.7841 8.67467 14.8394 8.68933L8.09775 15.431C7.96742 15.5613 7.96742 15.772 8.09775 15.9023C8.16275 15.9673 8.24808 16 8.33342 16C8.41875 16 8.50408 15.9673 8.56908 15.9023L15.3108 9.16067C15.3254 9.21567 15.3334 9.27367 15.3334 9.33333V12.3333C15.3334 12.5173 15.4824 12.6667 15.6668 12.6667C15.8511 12.6667 16.0001 12.5173 16.0001 12.3333V9.33333C16.0001 8.598 15.4021 8 14.6668 8Z"
                    fill="#F3F1F0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3636_1236">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div style={{ marginLeft: "15px" }}>
              {" "}
              <LineFarmListSVG />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export const Summary = ({
  result_id,
  onSelectFarm,
  project,
  selected_date,
  selected_parameter,
  project_id,
  top_5,
  worst_5,
  farm_health,
  StressWiseCount,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const parameterList = ["Crop Stress", "Water Stress", "Crop Growth"];
  const [selectedCrop, setSelectedCrop] = useState("Crop Stress");

  const [inferenceData, setInferenceData] = useState([]);
  const [underStressFarms, setUnderStressFarms] = useState([]);
  const [projectCount, setProjectCount] = useState(null);

  const stressCountObj = StressWiseCount.reduce((acc, curr) => {
    acc[curr.inference.trim()] = curr.count;
    return acc;
  }, {});

  const { categories, label } = getCategories(selected_parameter);

  // below two for FarmStressListCard
  const worstFarms = Object.keys(worst_5).map((farmId, index) => ({
    rank: index + 1,
    name: farmId,
    geojson: worst_5[farmId],
    arrow: "↗",
  }));

  const bestFarms = Object.keys(top_5).map((farmId, index) => ({
    rank: index + 1,
    name: farmId,
    geojson: top_5[farmId],
    arrow: "↗",
  }));

  // below two for FarmStressChartCard

  const worstFarmsData = Object.keys(worst_5).map((farmId, index) => ({
    name: farmId,
    CropStress: 7 * index + 9,
    WaterStress: 8 * index + 13,
    CropGrowth: 9 * index + 11,
  }));

  const bestFarmsData = Object.keys(worst_5).map((farmId, index) => ({
    name: farmId,
    CropStress: 10 * index + 9,
    WaterStress: 10 * index + 13,
    CropGrowth: 10 * index + 11,
  }));

  useEffect(() => {
    const fetchInference = async () => {
      try {
        const response = await fetchInferenceData({
          result_id: result_id, // Replace with your dynamic result_id if needed
        });
        setInferenceData(response.data);

        const severeStressFarms = response.data
          .filter((item) => item.inference === "Severe Crop Stress")
          .flatMap((item) => item.unique_farm_id); // Extract unique_farm_id from each matched item

        setUnderStressFarms(severeStressFarms); // Set the result to underStressFarms
        console.log("Under Stress Farms:", severeStressFarms);
        console.log("Fetched Inference Data:", response.data);
      } catch (error) {
        console.error("Error fetching Inference data:", error);
      }
    };

    fetchInference();
  }, []);

  useEffect(() => {
    // Suppose project.id is 7
    datesAnalysed({ project_id: project.id })
      .then((response) => {
        setProjectCount(response.data.project_count);
        console.log("Project count:", response.data.project_count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   // Suppose project.id is 7
  //   bestWorstFarms({ project_id: project_id })
  //     .then((response) => {
  //       console.log("nt:", response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "flex-start",
        height: "97%",
        zIndex: 1,
        paddingTop: "5.5em",
        paddingLeft: "4.5em",
        paddingRight: "0.5em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "15%",
          justifyContent: "space-between",
          paddingLeft: "5em",
          paddingRight: "3em",
          gap: "0.4em",
        }}
      >
        <div className={styles.card} style={{ width: "16%" }}>
          <div className={styles.cardContent}>
            Crop Type
            <span style={{ fontWeight: "bold", color: "#7A6A00" }}>
              {project.selected_crops}
            </span>
          </div>
          <img src={wheatImage} alt="Wheat" className={styles.cropImage} />
        </div>

        <div className={styles.card} style={{ background: "#E8F7DD" }}>
          <div className={styles.cardContent}>
            <span>
              <FaHandHoldingWater fill="grey" /> Total Area
            </span>

            <span style={{ fontWeight: "bold", color: "#387C05" }}>
              {" "}
              {`${(project.farm_area / 10000).toFixed(2)} ha`}
            </span>
          </div>
          <img src={wheatImage} alt="Wheat" className={styles.cropImage} />
        </div>

        <div
          className={styles.card}
          style={{ background: "rgba(219, 255, 135, 0.2)" }}
        >
          <div className={styles.cardContent}>
            <span>
              <CiCalendarDate fill="grey" /> Dates Analysed
            </span>

            <span style={{ fontWeight: "bold", color: "#B69E00" }}>
              {projectCount} days
            </span>
          </div>
        </div>

        <div className={styles.card} style={{ background: "#F7EFDD" }}>
          <div className={styles.cardContent}>
            <span>
              <FaHandHoldingWater fill="grey" /> Total Farms
            </span>

            <span style={{ fontWeight: "bold", color: "#E9A611" }}>
              {project.number_of_farms}
            </span>
          </div>
        </div>

        <div className={styles.card} style={{ background: "#E8EAFF" }}>
          <div className={styles.cardContent}>
            <span style={{}}>
              <FaRegHourglass fill="grey" /> Time Left
            </span>

            <span
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              3 months
            </span>
          </div>
        </div>

        <div className={styles.card} style={{ background: "#F6FFDC" }}>
          <div className={styles.cardContent}>
            <span>
              <FaHandHoldingWater fill="grey" /> Overall Farm Health
            </span>

            <span style={{ fontWeight: "bold", color: "black" }}>
              {farm_health}
            </span>
          </div>
        </div>

        {/* <div
          className={styles.card}
          style={{
            backgroundImage: `url(${farmImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={styles.cardContent}
            style={{
              color: "white",
              fontWeight: "bold",
              background: "#DBFF87",
            }}
          >
            Dates Analysed
            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <div style={{ fontWeight: "bold" }}>{projectCount} Days</div>
            </span>
          </div>
          
        </div> */}

        {/* <div
          className={styles.card}
          style={{
            backgroundImage: `url(${Farm2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={styles.cardContent}
            style={{ color: "white", fontWeight: "bold" }}
          >
            Time Left
            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              3 months
            </span>
          </div>
        </div> */}

        {/* <div
          className={styles.card}
          style={{
            backgroundImage: `url(${Farm3})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={styles.cardContent}>
            Overall Farm Health
            <span
              style={{
                fontSize: "14px",
                color: "rgba(80, 132, 10, 1)",
                fontWeight: "bold",
              }}
            >
              Good
            </span>
          </div>
        </div>

        <div
          className={styles.card}
          style={{
            backgroundImage: `url(${Farm3})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={styles.cardContent}>
            Total Farms
            <span
              style={{
                fontSize: "14px",
                color: "rgba(80, 132, 10, 1)",
                fontWeight: "bold",
              }}
            >
              <span>{project.number_of_farms}</span>
            </span>
          </div>
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "47%",
          justifyContent: "space-between",
          paddingLeft: "5em",
          paddingRight: "3em",
          gap: "0.9em",
          paddingTop: "2.7em",
        }}
      >
        {/* <UnderStressFarmComponent
          farmIds={underStressFarms}
          onSelectFarm={onSelectFarm}
        /> */}

        <FarmStressListCard
          worstFarms={worstFarms}
          bestFarms={bestFarms}
          onSelectFarm={onSelectFarm}
        />

        <FarmStressChartCard
          worstFarmsData={worstFarmsData}
          bestFarmsData={bestFarmsData}
        />

        <AdvisoryCard selected_date={selected_date} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "41%",
          justifyContent: "flex-start",
          paddingLeft: "5em",
          paddingRight: "3em",
          gap: "2.2em",
          paddingTop: "2.5em",
          marginBottom: "2em",
        }}
      >
        <StressCard
          selected_date={selected_date}
          selected_parameter={selected_parameter}
          categories={categories}
          stressCountObj={stressCountObj}
        />

        <RevenueCard />

        {/* <div
          style={{
            position: "relative",
            width: "35%",
            padding: "14px",
            backgroundColor: "rgba(247, 245, 243, 1)",
            borderRadius: "16px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            fontSize: "12px",
          }}
        >

        </div> */}

        <YieldCard />
        {/* <div>
          {" "}
          <YieldCardSVG />
        </div> */}
      </div>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          paddingRight: "3rem",
          paddingLeft: "9rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "40%",
              justifyContent: "space-between",
              background: "red",
            }}
          >
            <div className={styles.card}>
              <div className={styles.cardContent}>
                Crop Type
                <span
                  style={{
                    fontSize: "14px",
                    color: "rgba(80, 132, 10, 1)",
                    fontWeight: "bold",
                  }}
                >
                  {project.selected_crops}
                </span>
              </div>
              // <img src={wheatImage} alt="Wheat" className={styles.cropImage} />

              <div className={styles.Value1}></div>
            </div>

            <div
              className={styles.upperRowDiv}
              style={{
                width: "30%",
              }}
            ></div>

            <div
              className={styles.card}
              style={{
                backgroundImage: `url(${farmImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className={styles.cardContent}>
                Total Farms
                <span
                  style={{
                    fontSize: "14px",
                    color: "rgba(80, 132, 10, 1)",
                    fontWeight: "bold",
                  }}
                >
                  123
                </span>
              </div>

              <div className={styles.Value}> 1234</div>
            </div>

            <div
              className={styles.upperRowDiv}
              style={{
                width: "15%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "70%",
              justifyContent: "space-between",
            }}
          >
            <div
              className={styles.upperRowDiv}
              style={{
                display: "flex",
                gap: "15px",
                padding: "15px",
                background: "#f8f9fa",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                flexDirection: "column",
              }}
            >
              {inferenceData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: "60%",
                    backgroundColor: "#fff",
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "10px",
                    textAlign: "center",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    whiteSpace: "nowrap",
                  }}
                >
                  <p style={{ margin: "0", color: "#333" }}>
                    <strong>Inference:</strong> {item.inference}
                  </p>
                  <p style={{ margin: "4px 0", color: "#555" }}>
                    <strong>Count:</strong> {item.count}
                  </p>
                  <p style={{ margin: "4px 0", color: "#555" }}>
                    <strong>Percentage:</strong> {item.percentage}%
                  </p>
                </div>
              ))}
            </div>

            <div>
              <UnderStressFarmComponent
                farmIds={underStressFarms}
                onSelectFarm={onSelectFarm}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "35%",
            marginLeft: "2rem",
            gap: "1.5rem",
          }}
        >
          <div
            style={{
              height: "60%",
              background: "white",
              borderRadius: "18px",
            }}
          ></div>

          <div
            style={{
              height: "35%",
              background: "white",
              borderRadius: "18px",
            }}
          ></div>
        </div>
      </div> */}
    </div>
  );
};
