import React, { useEffect, useState } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "./../../../CSS/HomePage.module.css";
import { NavBar } from "../../Navbar/Navbar";
import {
  TutorialSVG,
  DemoSVG,
  NewSVG,
  MyProjectsTextIconSVG,
  SearchIconSVG,
} from "../../../Asset/SVG/AllSVG";
import { getProjects } from "../../API/API";
import { SavedProjectComponent } from "./SavedProjectComponent";
import { BeatLoader } from "react-spinners";

const theme = createTheme({
  palette: {
    background: {
      default: "rgba(247, 245, 243, 1)", // Set the background color here
    },
  },
});

/*
  navigate("/Insights", {
        state: {
          selectedCrop,
          selectedParameter,
          project,
          DateList,
          startDate,
          endDate,
          FarmList,
          Centroid,
          tiff_url: result.data.tiff_url,
          excel_url: result.data.excel_url,
          result_id: result.data.id,
          tiff_min_max: result.data.tiff_min_max,
          redsi_min_max: result.data.redsi_min_max,
          geojson: result.data.geojson,
        },
      });
      */

const MyProjectsFlexBox = () => {
  const navigate = useNavigate();
  const HandleNewProject = () => navigate("/Details");

  const HandleDemo = () => {
    window.location.href = "https://agri.arms4ai.com/";
  };
  // const screenHeight = window.innerHeight;
  // console.log("Screen height:", screenHeight, "pixels");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1.2rem",
        marginTop: "2.2rem",
        paddingRight: "2.5rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: "Red Hat Display",
            display: "flex",
            alignItems: "center",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          <MyProjectsTextIconSVG />
        </Typography>

        <div style={{ paddingLeft: "5vw", fontFamily: "Red Hat Display" }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            InputProps={{
              startAdornment: <SearchIconSVG />,
            }}
            InputLabelProps={{
              style: {
                fontFamily: "Red Hat Display", // Set the desired fontFamily here
              },
              shrink: true,
            }}
            sx={{
              "& input": {
                fontFamily: "Red Hat Display",
                width: "150px",
                height: "4px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px", // Adjust the value to control the oval shape
                boxShadow:
                  "1.625px 1.625px 3.25px 0px rgba(174, 174, 192, 0.2) inset, -1.625px -1.625px 1.625px 0px rgba(255, 255, 255, 0.7) inset, 2.4375px 2.4375px 4.875px 0px rgba(174, 174, 192, 0.4), -1.625px -1.625px 4.875px 0px rgba(255, 255, 255, 1)",
                border: "2px solid rgba(255, 255, 255, 1) !important", // Set border color to white
                "&:hover fieldset": {
                  borderColor: "rgba(240, 240, 243, 1)",
                },
                "& fieldset": {
                  borderColor: "rgba(240, 240, 243, 1)", // Set the default border color to white
                },
                "&:focus-within fieldset": {
                  borderColor: "white", // Set the focus border color to white
                },
              },
            }}
          />
        </div>
      </div>

      <div>
        <Grid
          sx={{
            display: "flex",
            gap: 5,
            flexDirection: "row",
            fontFamily: "Red Hat Display",
          }}
        >
          <Button
            onClick={HandleNewProject}
            variant="contained"
            sx={{
              fontFamily: "Red Hat Display",
              width: "140px",
              height: "40px",
              borderRadius: "30px",
              textTransform: "none", // prevents upper case
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px -4px 8px white,0px 4px 8px rgba(0, 0, 0, 0.2)", // Neomorphic shadow
              border: "0px",
              background: "rgba(247, 245, 243, 1)",
              color: "rgba(76, 185, 99, 1)",
              fontWeight: "800",
              fontSize: "10px",
              "&:hover": {
                background: "rgba(247, 245, 243, 1)",
              },
            }}
          >
            <NewSVG />
            <span style={{ marginLeft: "8px" }}>New Project &gt; </span>{" "}
            {/* Adjust the spacing */}
          </Button>

          <Button
            onClick={HandleDemo}
            variant="contained"
            sx={{
              width: "95px", // Adjust the width of the button as needed
              height: "40px", // Adjust the height of the button as needed
              borderRadius: "30px", // Makes it oval-shaped
              fontFamily: "Red Hat Display",
              display: "flex",
              textTransform: "none",
              alignItems: "center",
              justifyContent: "center",
              background:
                "linear-gradient(180deg, #DDE0FF 9.62%, #9EA7F9 86.54%)",
              boxShadow:
                "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
              border: "0px", // Set border color to white
              transition: "border-color 0.3s",
              fontWeight: "800px",
              fontSize: "10px",
            }}
          >
            <DemoSVG />
            <span style={{ marginLeft: "8px" }}>Demo</span>{" "}
            {/* Adjust the spacing */}
          </Button>

          <Button
            variant="contained"
            sx={{
              width: "95px", // Adjust the width of the button as needed
              height: "40px", // Adjust the height of the button as needed
              borderRadius: "30px", // Makes it oval-shaped
              fontFamily: "Red Hat Display",
              display: "flex",
              textTransform: "none",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(247, 245, 243, 1)",
              color: "rgba(107, 119, 129, 1)",
              boxShadow: "0px -4px 8px white,0px 4px 8px rgba(0, 0, 0, 0.2)", // Neomorphic shadow
              border: "0px",
              fontWeight: "bold",
              fontSize: "10px",
              "&:hover": {
                background: "rgba(247, 245, 243, 1)",
              },
            }}
          >
            <TutorialSVG />
            <span style={{ marginLeft: "6px" }}>Tutorial</span>
          </Button>
        </Grid>
      </div>
    </div>
  );
};

const GetAllProjects = async () => {
  try {
    const response = await getProjects();
    return response.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return null; // Return null or an empty array if you prefer to indicate failure
  }
};

const HomePage = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [error, setError] = useState(null);

  const InHomePageFlag = true;
  const navigate = useNavigate();
  const BackButtonToNavbar = () => {
    navigate("/");
  };

  const fetchProjects = async () => {
    setLoading(true); // Set loading to true when retrying
    setError(null); // Clear any previous error

    try {
      const data = await GetAllProjects();

      if (data) {
        setProjects(data.reverse()); // Store the fetched projects in state
      } else {
        setError("An error occurred while fetching the projects.");
      }
    } catch (error) {
      setError("An error occurred while fetching projects.");
    } finally {
      setLoading(false); // Set loading to false after fetching is complete
    }
  };

  // Retry Fetch Projects function
  const retryFetchProjects = () => {
    fetchProjects(); // Retry fetching projects
  };

  // useEffect to fetch projects on component mount
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    //theme color
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          {/* Buttons*/}
          <NavBar
            InHomePageFlag={InHomePageFlag}
            BackButtonInNavbar={BackButtonToNavbar}
          />
          <hr
            style={{
              position: "absolute",
              top: "90px",
              left: "0px",
              width: "100%",
              borderBottom: "2px solid rgba(76, 185, 99, 1)",
            }}
          />
          {/* myProjects search button  new-demo-tutorial*/}
          <MyProjectsFlexBox />
          {/* Main Project Container */}
          <div className={styles.Outer_div}>
            {" "}
            <div className={styles.scrollable}>
              {loading ? (
                <div className={styles.centered_loading}>
                  Loading Results{" "}
                  <span style={{ marginLeft: "4px", marginTop: "0.2rem" }}>
                    <BeatLoader
                      size={4}
                      margin={2}
                      color="rgba(15, 44, 90, 1)"
                    />
                  </span>
                </div> // Display while loading
              ) : error ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {error}
                  <br />
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      retryFetchProjects();
                    }}
                    style={{
                      color: "rgba(15, 44, 90, 1)",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Click here to fetch the projects again
                  </a>
                </div> // Display error with retry link
              ) : projects.length > 0 ? (
                projects.map((project) => (
                  <SavedProjectComponent key={project.id} project={project} />
                ))
              ) : (
                <div className={styles.empty_projects}>
                  Projects will appear here
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Arms 4 AI*/}
        <div
          style={{
            marginBottom: "2rem",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "-21px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Red Hat Display",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Powered By Arms 4 AI
            </Typography>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
