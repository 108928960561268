import {
  LeafSVG,
  HaIconSVG,
  SunflowerIconSVG,
  ViewCircleIconSVG,
  DividerLineIconSVG,
  CirclesIconSVG,
} from "../../../Asset/SVG/AllSVG";
import { Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchAllResultData,
  bestWorstFarms,
  StressWiseFarms,
} from "../../API/API";
import { IoPlayOutline } from "react-icons/io5";
import styles from "../../../CSS/HomePage.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = () => {
  toast.loading("Fetching Results!", {
    position: "top-center",
  });
};

const notify2 = () => {
  toast.error("An error occured, pls try again!", {
    position: "top-center",
  });
};

export const SavedProjectComponent = ({ project }) => {
  const token = localStorage.getItem("token");
  const {
    project_title,
    project_type,
    report_type,
    selected_crops,
    selected_location,
    selected_parameters,
    starting_date,
    uploaded_geojson,
    drawn_geojson,
    details,
    number_of_farms,
    farm_area,
    result_count,
    project_id = project.id,
  } = project;

  const navigate = useNavigate();

  const groupedByParameter = (all_results_data) => {
    return all_results_data.reduce((acc, item) => {
      const key = item.selected_parameter;

      // If key doesn't exist, create an empty array
      if (!acc[key]) {
        acc[key] = [];
      }

      // Push the current item to the correct parameter key
      acc[key].push(item);

      return acc;
    }, {}); // <- Make sure the reduce() result is returned
  };

  const ToInsightsPage = async () => {
    try {
      notify();

      // const response = await fetchAllResultData(project_id);
      // const resultData = response.data.data;

      // // Fetch additional TIFF-related data
      // const { tiff_data, marks } = await fetchData();
      // const { tile_url } = await fetchTileLatest();

      const { all_results_data } = await fetchAllResultData(project_id);

      const lastResult = all_results_data[all_results_data.length - 1];

      const { top_5, worst_5, farm_health } = await bestWorstFarms({
        project_id: project.id,
        geojson_data: project.geojson,
      });

      const ParameterWiseList = groupedByParameter(all_results_data);

      // Fetch stresswise farms using lastResult values.
      let temp;
      try {
        const response = await StressWiseFarms(
          lastResult.selected_date,
          lastResult.selected_parameter
        );
        // Assume the response data is already the object you need.
        temp = response.data;
      } catch (error) {
        console.error("Error fetching stresswise farms:", error);
        // Optionally assign an empty object so further processing won't fail.
        temp = {};
      }

      // Create an array of objects that shows the inference type and count
      const StressWiseCount = Object.keys(temp).map((key) => ({
        inference: key,
        count: temp[key].length,
      }));

      navigate("/Insights", {
        state: {
          FarmList: lastResult.geojson,
          tiff_url: lastResult.tiff_url,
          excel_url: lastResult.excel_url,
          result_id: lastResult.id,
          tiff_min_max: lastResult.tiff_min_max,
          geojsonData: lastResult.geojson,
          tile_url: lastResult.tile_url,
          project,
          selected_date: lastResult.selected_date,
          selected_parameter: lastResult.selected_parameter,
          ParameterWiseList,
          Parameter_Data: ParameterWiseList[lastResult.selected_parameter],
          project_id: project.id,
          top_5,
          worst_5,
          farm_health,
          StressWiseCount,
          all_results_data,
        },
      });
    } catch (err) {
      notify2();
      console.error("Error fetching result:", err);
    }
  };

  // const fetchTileLatest = async () => {
  //   try {
  //     const response = await fetch(`${API_URL}/api/load_tiff`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`, // Replace with your JWT token
  //       },
  //       body: JSON.stringify({
  //         selected_date: {},
  //       }),
  //     });

  //     let tile_url = [];
  //     const data = await response.json();
  //     tile_url = data.tile_url;
  //     return { tile_url };
  //   } catch (error) {
  //     console.error("Error loading TIFF:", error);
  //   }
  // };

  const ToRunAnalysis = async () => {
    try {
      navigate("/ParameterSelection", {
        state: {
          project,
        },
      });
    } catch (err) {
      console.error("Error fetching result:", err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1em",
        flexWrap: "wrap",
      }}
    >
      {/* div 1 */}
      <Box sx={{ flex: "1 1 5%" }}>
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            fontWeight: "bold",
            color: "rgba(98, 98, 98, 1)",
            fontSize: "16px",
            lineHeight: "26px",
            letterSpacing: "0.1em",
            textAlign: "left",
          }}
        >
          {project_title}
        </Typography>
      </Box>

      {/* div 2 */}
      <Box sx={{ flex: "1 1 20%", textAlign: "center" }}>
        <CirclesIconSVG />
      </Box>

      {/* div 3 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1vw",
          flex: "1 1 27%",
        }}
      >
        <div style={{ fontSize: "14px" }}>
          <LeafSVG />
          <Box
            component="span"
            sx={{ verticalAlign: "4px", marginLeft: "8px" }}
          >
            {number_of_farms}
          </Box>
        </div>
        <div style={{ fontSize: "14px" }}>
          <HaIconSVG />
          <Box
            component="span"
            sx={{ verticalAlign: "4px", marginLeft: "8px" }}
          >
            {`${(farm_area / 10000).toFixed(2)} ha`}
          </Box>
        </div>
        <div style={{ fontSize: "14px" }}>
          <SunflowerIconSVG />
          <Box
            component="span"
            sx={{ verticalAlign: "4px", marginLeft: "8px" }}
          >
            {selected_crops}
          </Box>
        </div>
        <div style={{ fontSize: "14px" }}>
          <Box
            component="span"
            sx={{ verticalAlign: "4px", marginLeft: "8px", fontWeight: "bold" }}
          >
            {starting_date}
          </Box>
        </div>
      </Box>

      {/* div 4 */}
      <div
        style={{
          cursor: "default",
          width: "10%",
          fontSize: "13px",
          color: "rgba(98, 98, 98, 1)",
          marginTop: "-4px",
        }}
      >
        {result_count > 0 ? (
          <span style={{ fontWeight: "bold" }}>{result_count}</span>
        ) : (
          "Analyse now!"
        )}{" "}
        {result_count > 0 && "Dates Analysed"}
      </div>

      <div
        onClick={ToRunAnalysis}
        className={styles.RunAnalysis}
        data-tooltip="Run Analysis"
        style={{ marginTop: "-5px" }}
      >
        <IoPlayOutline color="white" size={15} />
      </div>

      {/* div 5 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "14px",
          flex: "1 1 5%",
          marginTop: "-7px",
        }}
      >
        <div>
          <Button
            className={styles.View}
            onClick={ToInsightsPage}
            data-tooltip="Latest Analysis"
            sx={{
              textTransform: "none",
              fontFamily: "Red Hat Display",
              color: "white",
            }}
          >
            View
          </Button>
        </div>
      </Box>

      <div style={{}}>
        {" "}
        <DividerLineIconSVG />
      </div>
      <ToastContainer />
    </Box>
  );
};
