import React, { useState, useContext } from "react";
import styles from "../../CSS/Login.module.css";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AgriAiSVG,
  SignInSVG,
  ForgotPassSVG,
  PasswordIconSVG,
  AtIconSVG,
} from "../../Asset/SVG/AllSVG";
import { useNavigate } from "react-router-dom";
import { LoginEndpoint } from "../API/API";
import AuthContext from "../AuthContext/AuthContext";
import useUserStore from "../useUserStore";

const Login = () => {
  const navigate = useNavigate();
  const isHeightSm = useMediaQuery("(max-height:600px)");
  const isHeightMd = useMediaQuery("(max-height:800px)");
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [lowerText, SetlowerText] = useState("");
  const [submitClicked, SetSubmitClicked] = useState(false);

  const delayerFunc = () => {
    setTimeout(() => {
      SetlowerText("");
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      SetlowerText("Email cannot be empty!");
      delayerFunc();
      return;
    }
    if (password === "") {
      SetlowerText("Password can't be empty");
      delayerFunc();
      return;
    } else {
      SetSubmitClicked(true);
      try {
        const responseData = await LoginEndpoint(email, password);
        // Check if LoginEndpoint was successful
        if (responseData.data.access_token) {
          const token = responseData.data.access_token;
          login(token);
          const { setUserDetails } = useUserStore.getState();
          await setUserDetails();
          SetlowerText("Login successful!");
          handleLogIn();
        } else {
          SetlowerText(responseData.data.msg);
        }

        SetSubmitClicked(false); // Reset the submit button state
        delayerFunc();
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  const handleForgotPass = async (e) => {
    navigate("/ForgotPass");
  };

  const handleCreate = async (e) => {
    navigate("/CreateAccount");
  };

  const handleLogIn = () => {
    navigate("/HomePage");
  };

  return (
    <div className={styles.background_container}>
      <div className={styles.Infra}>
        <AgriAiSVG color="white" />
      </div>

      <div className={styles.RightDiv}>
        <Paper
          elevation={3}
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 0.66)), linear-gradient(0deg, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0.23))",
            border: "1px solid rgba(255, 255, 255, 0.23)",
            width: "80%",
            borderRadius: "25px",
            backdropFilter: "blur(9px)",
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            paddingTop: "1em",
            alignItems: "center",
            justifyContent: "space-around",
            paddingRight: "0.9rem",
          }}
        >
          <div>
            <Box
              sx={{
                paddingTop: isHeightSm
                  ? "0.1rem"
                  : isHeightMd
                  ? "0.5rem"
                  : "1rem",
              }}
            >
              {" "}
              <SignInSVG />
            </Box>

            <Typography
              sx={{ fontSize: "12px", fontFamily: "Red Hat Display" }}
            >
              Dont have an account ?{" "}
              <a
                onClick={handleCreate}
                style={{
                  color: "rgba(210, 138, 0, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Create Account
              </a>
            </Typography>
          </div>

          <div style={{ paddingTop: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                paddingTop: "1rem",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <AtIconSVG />
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                pt: "1.3em",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "15px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <PasswordIconSVG />
                      <span style={{ verticalAlign: "super", color: "Red" }}>
                        *
                      </span>
                    </div>
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>

            <div
              style={{
                fontSize: "12px",
                color: lowerText ? "red" : "transparent",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {lowerText ? <div>{lowerText}</div> : <div>yess</div>}
            </div>
          </div>

          <div
            onClick={handleForgotPass}
            style={{
              cursor: "pointer",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <ForgotPassSVG />
          </div>
          <div
            style={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "1rem",
            }}
          >
            {" "}
            <Button
              onClick={handleSubmit}
              variant="contained"
              component="div"
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(72, 87, 99, 1)",
                height: "52px",
                textTransform: "none",
                textDecoration: "none", // Add underline
                padding: "2px 165px", // Adjust padding to make the button smaller
                borderRadius: "8px",
                background: "rgba(76, 84, 209, 1)",
                color: "white",
                width: "7%",
                whiteSpace: "nowrap",
              }}
            >
              {submitClicked ? "Signing In..." : "Sign In"}
            </Button>
          </div>

          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Red Hat Display",
              fontWeight: "bold",
              pt: "2rem",
              pb: "1rem",
            }}
          >
            Powered by ARMS 4 AI
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
