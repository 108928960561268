import React, { useState } from "react";
import styles from "../../CSS/Login.module.css";
import { Paper, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailVerifiedLottie from "../../Asset/Lottie/EmailVerifiedLottie.json";
import Lottie from "lottie-react";
import { AgriAiSVG, EmailVerifiedSVG } from "../../Asset/SVG/AllSVG";

const EmailVerified = () => {
  const navigate = useNavigate();
  const [lowerText, SetlowerText] = useState("");
  const [submitClicked, SetSubmitClicked] = useState(false);

  function delayerFunc() {
    setTimeout(() => {
      SetlowerText("");
    }, "2000");
  }
  const handleSubmit = async (e) => {
    navigate("/");
  };

  return (
    <div className={styles.background_container}>
      <div className={styles.Infra}>
        <AgriAiSVG color="white" />
      </div>

      <div className={styles.RightDiv}>
        <Paper
          elevation={3}
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 0.66)), linear-gradient(0deg, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0.23))",
            border: "1px solid rgba(255, 255, 255, 0.23)",
            width: "80%",
            borderRadius: "25px",
            backdropFilter: "blur(9px)",
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            paddingTop: "1em",
            alignItems: "center",
            justifyContent: "space-around",
            paddingRight: "0.9rem",
          }}
        >
          <div
            style={{
              position: "absolute",
              marginBottom: "18rem",
              width: "320px",
              marginTop: "1rem",
            }}
          >
            <Lottie animationData={EmailVerifiedLottie} loop={true} />
          </div>

          <div style={{ marginTop: "17rem" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5rem",
              zIndex: "1000",
              marginTop: "2rem",
            }}
          >
            <div>{EmailVerifiedSVG}</div>
            <Button
              onClick={handleSubmit}
              variant="contained"
              component="div"
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(72, 87, 99, 1)",
                height: "52px",
                textTransform: "none",
                textDecoration: "none", // Add underline
                padding: "2px 165px", // Adjust padding to make the button smaller
                borderRadius: "8px",
                background: "rgba(76, 84, 209, 1)",
                color: "white",
                width: "7%",
                whiteSpace: "nowrap",
              }}
            >
              {submitClicked ? "..." : "Back To Login"}
            </Button>
          </div>

          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Red Hat Display",
              fontWeight: "bold",
              mt: "3rem",
              mb: "1rem",
            }}
          >
            Powered by ARMS 4 AI
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default EmailVerified;
