import React, { useState, useRef, useEffect } from "react";
import { Button, Box } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "../../CSS/AOI.module.css";
import "leaflet/dist/leaflet.css";
import { NavBar } from "..//Navbar/Navbar";
import { parse, format } from "date-fns";
// import "../../CSS/App.css";
// import { parseISO, format } from "date-fns";
// import { utcToZonedTime } from "date-fns-tz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import theme from "../../Asset/Theme/theme";
import { useLocation } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { fetchDates } from "../API/API";
import { MultiSelect } from "react-multi-select-component";
import { BeatLoader } from "react-spinners";
import geojsonvt from "geojson-vt";
import L from "leaflet";

import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from "react-leaflet";
import {
  ParameterIcon,
  LeafSVG,
  SunflowerIconSVG,
  DateIcon,
  DropDownIcon,
} from "../../Asset/SVG/AllSVG";
import DatePicker from "react-datepicker";

const fetchDateList = async (
  GeojsonData,
  startDate,
  endDate,
  selectedParameter,
  selectedFarmIds
) => {
  try {
    const response = await fetchDates({
      GeojsonData,
      startDate,
      endDate,
      selectedParameter,
      selectedFarmIds,
    });

    return {
      DateList: response.data.available_dates,
      FarmList: response.data.selectedFarms,
      Centroid: response.data.centroid,
    }; // Ensure you return the data
  } catch (error) {
    console.error("Error fetching dates:", error);

    // Handle known error response from the server
    if (error.response && error.response.data && error.response.data.msg) {
      return {
        error: error.response.data.msg,
      };
    }

    // Handle other errors
    return {
      error: "An unexpected error occurred. Please try again later.",
    };
  }
};

const SetViewOnClick = ({ coords, map }) => {
  useEffect(() => {
    if (coords && map) {
      map.setView(coords, 12);
    }
  }, [coords, map]);

  return null;
};

const Selector = ({ options, initialText, onChange }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="selector">
      <select
        className={`${styles.selectDropdown} ${
          selectedOption ? styles.selected : ""
        }`}
        name="options"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value="" disabled hidden>
          {initialText}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const GeoJSONVTLayer = ({ geojsonData }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current || !geojsonData) return;

    // Define options for leaflet-geojson-vt
    const options = {
      maxZoom: 16, // Maximum zoom level for tiling
      tolerance: 3, // Tile tolerance
      debug: 0, // Debug mode off
      style: {
        fillColor: "#1EB300", // Polygon fill color
        color: "#F2FF00", // Polygon stroke color
        weight: 1, // Stroke width
      },
    };

    // Create the GeoJSON-VT layer
    const vtLayer = L.geoJson.vt(geojsonData, options);
    console.log("vtLayer");
    // Add the layer to the map
    vtLayer.addTo(mapRef.current);

    // Cleanup on component unmount
    return () => {
      mapRef.current.removeLayer(vtLayer);
    };
  }, [geojsonData]);

  return null;
};

export const ParameterSelection = () => {
  const { state } = useLocation();

  const [geojsonData, setGeojsonData] = useState(null);

  useEffect(() => {
    // Fetch the GeoJSON file from the public folder
    fetch("/autumn_farm_1.geojson")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setGeojsonData(data))
      .catch((error) => console.error("Error loading GeoJSON:", error));
  }, []);

  const { project } = state;

  const [coords, setCoords] = useState(null);

  const onEachFarm = (x, layer) => {
    const isSelected = selectedFarmIds.includes(x.properties.Polygon_ID);

    layer.setStyle({
      weight: 4,
      color: isSelected ? "red" : "#4169E1",
    });

    layer.on({
      mouseover: () => onFarmMouseOver(x, layer),
      mouseout: () => onFarmMouseOut(layer),
      click: () => onFarmClick(x, layer),
    });
  };

  const onFarmClick = (x, layer) => {
    const farmId = x.properties.Polygon_ID;
    let newSelectedFarmIds;
    let newSelectedOptions;

    if (selectedFarmIds.includes(farmId)) {
      // If already selected, remove it
      newSelectedFarmIds = selectedFarmIds.filter((id) => id !== farmId);
      newSelectedOptions = selected.filter((item) => item.value !== farmId);
      layer.setStyle({ color: "#4169E1" });
    } else {
      // If not selected, add it
      newSelectedFarmIds = [...selectedFarmIds, farmId];
      newSelectedOptions = [
        ...selected,
        { label: `Farm ${farmId}`, value: farmId },
      ];
      layer.setStyle({ color: "red" });
    }

    setSelectedFarmIds(newSelectedFarmIds);
    setSelected(newSelectedOptions);
  };

  const onFarmMouseOver = (x, layer) => {
    const popupContent = ReactDOMServer.renderToString(
      <PopupContent properties={x.properties} />
    );
    layer.bindPopup(popupContent).openPopup();
  };

  const onFarmMouseOut = (layer) => {
    layer.closePopup();
  };

  const PopupContent = ({ properties }) => {
    return (
      <div>
        <strong>Farm :</strong> {properties.Polygon_ID}
      </div>
    );
  };

  const GeojsonData = project.geojson;

  const [layers] = useState({
    openStreetMap: false,
    esriWorldImagery: true,
  });

  const ParameterList = project.selected_parameters
    .split(",")
    .map((param) => param.trim());
  // Remove the curly braces and then split by comma
  const CropList = project.selected_crops
    .replace(/[{}]/g, "")
    .split(",")
    .map((crop) => crop.trim());

  const [selectedParameter, setSelectedParameter] = useState("");
  const [selectedCrop, setSelectedCrop] = useState(CropList);

  const [selectedFarmIds, setSelectedFarmIds] = useState([]);
  const isBothSelected = selectedParameter && selectedFarmIds.length;

  const [date, setDate] = useState(new Date());

  const [startDate, setStartDate] = useState(() => {
    return parse(project.starting_date, "dd/MM/yyyy", new Date())});

    
    const [startDateString, setStartDateString] = useState(() => {
      if (!project.starting_date) return ""; // if empty, return ""
      try {
        // Parse "d/m/yyyy" (e.g., "15/3/2025")
        // If your date uses single or double digits for day/month, 'd' and 'M' handle both.
        const parsedDate = parse(project.starting_date, "d/M/yyyy", new Date());
        // Format to "yyyy-MM-dd" (e.g., "2025-03-15")
        return format(parsedDate, "yyyy-MM-dd");
      } catch (err) {
        // If parse fails, return an empty string or handle error
        return "";
      }
    });

  const [endDate, setEndDate] = useState(new Date());
  const [endDateString, setEndDateString] = useState( date.toISOString().substring(0, 10));

  const [loading, setLoading] = useState(false);

  const handleStartDateChange = (date) => {
    // console.log(date);
    setStartDate(date);
    setStartDateString(format(date, "yyyy-MM-dd"));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setEndDateString(format(date, "yyyy-MM-dd"));
  };

  const navigate = useNavigate();
  const BackButtonToNavbar = () => navigate("/HomePage");

  const notify = (message) => {
    toast.warn(message, {
      position: "top-center",
    });
  };

  const ToDateSelection = async () => {
    setLoading(true);

    const result = await fetchDateList(
      GeojsonData,
      startDateString,
      endDateString,
      selectedParameter,
      selectedFarmIds
    );

    if (result.DateList) {
      navigate("/DateSelection", {
        state: {
          selectedCrop,
          selectedParameter,
          project,
          DateList: result.DateList,
          startDate,
          endDate,
          selectedFarmIds,
          FarmList: result.FarmList,
          Centroid: result.Centroid,
        },
      });
    } else if (result.error) {
      // Notify the user with the specific error message returned from the server
      notify(result.error);
      setLoading(false);
    }
  };

  const mapRef = useRef();
  const multiselectRef = useRef();

  useEffect(() => {
    console.log([project.centroid.latitude, project.centroid.longitude]);
    setCoords([project.centroid.latitude, project.centroid.longitude]);
  }, []);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleSelect = (selectedItems) => {
    setSelectedFarmIds(selectedItems.map((item) => item.value));
    setSelected(selectedItems);
  };

  useEffect(() => {
    const newOptions = GeojsonData.features.map((feature) => ({
      label: `Farm ${feature.properties.Polygon_ID}`,
      value: feature.properties.Polygon_ID,
    }));

    setOptions(newOptions);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={2000} />{" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Buttons*/}
        <NavBar
          InHomePageFlag={false}
          BackButtonInNavbar={BackButtonToNavbar}
          ProjectName={project.project_title}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: "3em",
            pr: "3em",
            gap: "2rem",
          }}
        >
          <Box className={styles.left_container_parameterselection}>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "3rem",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    marginLeft: "2rem",
                    position: "relative",
                    fontWeight: "bold",
                    color: "#0F2C5A",
                    fontSize: "18px",
                  }}
                >
                  Initiate Analysis
                  <span
                    style={{
                      content: '""',
                      position: "absolute",
                      width: "50%",
                      height: "4px", // Adjust the height as needed
                      bottom: "-5px", // Adjust the position as needed
                      left: "0",
                      backgroundColor: "rgba(255, 0, 0, 1)",
                    }}
                  ></span>
                </span>
              </div>

              {/*Select Parameters*/}
              <Box className={styles.select_item}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                  }}
                >
                  {" "}
                  {ParameterIcon}{" "}
                  <span style={{ color: "transparent" }}>o</span> Select
                  Parameters
                </span>
                <Selector
                  options={ParameterList}
                  initialText="Parameter"
                  onChange={(value) => setSelectedParameter(value)}
                />
              </Box>
              <hr
                style={{
                  marginTop: "1.4rem",
                  width: "85%",
                  height: "0.2px",
                  border: "none",
                  color: "lightgrey",
                  background: "lightgrey",
                }}
              />

              {/*Select Farm*/}
              <Box className={styles.select_item}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <LeafSVG />
                  <span style={{ color: "transparent" }}>o</span> Select Farm
                </span>
              </Box>

              <div className={styles.multiselect}>
                {" "}
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={handleSelect}
                  placeholder="Your Custom Placeholder..."
                  ref={multiselectRef}
                  overrideStrings={{
                    selectSomeItems: "No Farm Selected",
                    allItemsAreSelected: "All Farms are selected.",
                  }}
                />
              </div>

              {/*Select Crop*/}
              <Box className={styles.select_item}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <SunflowerIconSVG />
                  <span style={{ color: "transparent" }}>o</span> Crop Type
                </span>

                {/* <Selector
                  options={CropList}
                  initialText="Crops"
                  onChange={(value) => setSelectedCrop(value)}
                /> */}
                <span
                  style={{
                    background:
                      "linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    display: "inline-block",
                  }}
                >
                  {CropList}
                </span>
              </Box>
              <hr
                style={{
                  marginTop: "1.6rem",
                  width: "85%",
                  height: "0.2px",
                  border: "none",
                  color: "lightgrey",
                  background: "lightgrey",
                }}
              />
              {/*Choose Date*/}
              <div className={styles.date_calendar}>
                <Box className={styles.date}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {DateIcon}
                    <span style={{ marginLeft: "-35px" }}>Date</span>
                  </span>
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.5rem",
                    paddingLeft: "1rem",
                    gap: "3rem",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      fontSize: "13px",
                      justifyContent: "space",
                      alignItems: "center",
                      marginLeft: "15px",
                      textAlign: "left",
                      fontFamily: "Red Hat Display",
                      color: "rgba(0, 0, 0, 0.65)",
                      height: "1rem",
                    }}
                  >
                    From
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "6px",
                          overflow: "hidden",
                          fontFamily: "Red Hat Display",
                          background: "yellow",
                          height: "0rem",
                          width: "0",
                        }}
                      >
                        {" "}
                        {/* Hide the input field */}
                        <DatePicker
                          className="datePickerStartDate"
                          selected={startDate}
                          onChange={handleStartDateChange}
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>

                      <p
                        onClick={() => {
                          document
                            .getElementsByClassName("datePickerStartDate")[0]
                            .click();
                        }}
                        style={{
                          marginRight: "6px",
                          fontSize: "13px",
                          fontFamily: "Red Hat Display",
                          marginTop: "11px",
                          color: "#000",
                          fontWeight: "400",
                        }}
                      >
                        {startDateString}{" "}
                        <span style={{ marginLeft: "4px" }}>
                          <DropDownIcon />
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      color: "white",
                      fontSize: "13px",
                      justifyContent: "space",
                      alignItems: "center",
                      marginLeft: "10px",
                      textAlign: "left",
                      fontFamily: "Red Hat Display",
                      color: "rgba(0, 0, 0, 0.65)",
                      height: "1rem",
                    }}
                  >
                    To
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "6px",
                          overflow: "hidden",
                          fontFamily: "Red Hat Display",
                          background: "yellow",
                          height: "0rem",
                          width: "0",
                          zIndex: "9999",
                        }}
                      >
                        {" "}
                        {/* Hide the input field */}
                        <DatePicker
                          className="datePickerEndDate"
                          selected={endDate}
                          onChange={handleEndDateChange}
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>

                      <p
                        onClick={() => {
                          document
                            .getElementsByClassName("datePickerEndDate")[0]
                            .click();
                        }}
                        style={{
                          marginRight: "6px",
                          fontSize: "13px",
                          fontFamily: "Red Hat Display",
                          marginTop: "11px",
                          color: "#000",
                          fontWeight: "300",
                        }}
                      >
                        {endDateString}{" "}
                        <span style={{ marginLeft: "4px" }}>
                          <DropDownIcon />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className={
                  isBothSelected ? styles.selectedButton : styles.defaultButton
                }
                onClick={ToDateSelection}
                disabled={!isBothSelected}
                style={{
                  border: "0px", // Set border color to white
                  color: "white",
                  fontSize: "12px",
                  textTransform: "none",
                  fontFamily: "Red Hat Display",
                  width: "22vw",
                  height: "6vh",
                  borderRadius: "7px",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Fetching Dates{" "}
                    <span style={{ marginLeft: "4px", marginTop: "0.2rem" }}>
                      {" "}
                      <BeatLoader size={4} margin={2} color="white" />
                    </span>
                  </div>
                ) : (
                  "Continue"
                )}
              </Button>
            </Box>
          </Box>

          <div className={styles.map_container}>
            <MapContainer
              center={[28.7041, 77.1025]} // long,lat of Delhi
              zoom={5}
              scrollWheelZoom={true}
              ref={mapRef}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
            >
              <GeoJSONVTLayer geojsonData={geojsonData} />
              <SetViewOnClick coords={coords} map={mapRef.current} />
              <GeoJSON
                key={`${GeojsonData}-${selectedFarmIds.join(",")}`}
                data={GeojsonData}
                onEachFeature={onEachFarm}
              />
              <FeatureGroup></FeatureGroup>
              <LayersControl position="topright">
                <LayersControl.BaseLayer
                  checked={layers.openStreetMap}
                  name="Google Maps"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key=$`}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer
                  checked={layers.esriWorldImagery}
                  name="Satellite"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=$`}
                    // url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
            </MapContainer>
          </div>
        </Box>
      </div>
      <CssBaseline />
    </ThemeProvider>
  );
};
