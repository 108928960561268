import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import dayjs from "dayjs";

const theme = createTheme({
  typography: {
    fontFamily: "'Red Hat Display', sans-serif",
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "'Red Hat Display', sans-serif",
        },
      },
    },
  },
});

export default function BasicDateCalendar({ onDateSelect, initialDate }) {
  const handleDateChange = (date) => {
    onDateSelect(date);
  };

  const disablePastDates = (date) => {
    return date.isBefore(dayjs(), "day");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={initialDate} // Set value to initialDate to highlight selected date
          onChange={handleDateChange}
          // shouldDisableDate={disablePastDates}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
