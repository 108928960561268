import React, { useState, useContext, useEffect } from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Settings, ExitToApp, Book, Map } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext/AuthContext";
import useUserStore from "../useUserStore";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.text.primary,
    boxShadow:
      "rgb(255 255 255 / 0.15) 0px 0px 0px 1px, rgb(0 0 0 / 20%) 0px 0px 5px 0px, rgb(0 0 0 / 30%) 0px 10px 15px -3px",
    "& .MuiMenu-list": {
      padding: "0",
    },
    backgroundColor: "linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  },
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const ProfileBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useContext(AuthContext);

  const { name, email } = useUserStore((state) => ({
    name: state.name,
    email: state.email,
  }));

  const navigate = useNavigate();
  const LogOutOnclick = async () => {
    useUserStore.getState().clearUserDetails();
    logout();
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <CustomAvatar>A</CustomAvatar>
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box sx={{ padding: "16px", textAlign: "left" }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontFamily: "Red Hat Display" }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontFamily: "Red Hat Display" }}
          >
            {email}
          </Typography>
        </Box>
        <Divider />
        <MenuItem
          onClick={handleClose}
          sx={{
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            color: "#007bff",
          }}
        >
          <Settings fontSize="small" /> Settings
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          sx={{
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            color: "#007bff",
          }}
        >
          <Book fontSize="small" /> Documentation
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={LogOutOnclick}
          sx={{
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            color: "#007bff",
          }}
        >
          <ExitToApp fontSize="small" /> Logout
        </MenuItem>
      </StyledMenu>
    </Box>
  );
};

export default ProfileBar;
