// MapComponent.jsx
import React, { useState } from "react";
import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MapComponent = () => {
  const [viewport, setViewport] = useState({
    longitude: 80.20550413838318,
    latitude: 27.791973428263272,
    zoom: 10,
  });

  const tileUrl = "https://cloud.arms4ai.com/tiles/{z}/{x}/{y}.png";
  // Example URL from the Python script

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Map
        initialViewState={viewport}
        mapboxAccessToken="pk.eyJ1IjoibmVvYWx3YXl6IiwiYSI6ImNtMjFycms2bTBwMnQyaXIxODdrZ2oxcG0ifQ.DfeUBOZGAfZENXqUSs2yjw"
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
      >
        {/* Add the custom raster source and layer */}
        <Source
          id="tiff-source"
          type="raster"
          tiles={[tileUrl]}
          tileSize={256}
        />
        <Layer
          id="tiff-layer"
          type="raster"
          source="tiff-source"
          paint={{
            "raster-opacity": 0.9,
          }}
        />
      </Map>
    </div>
  );
};

export default MapComponent;
