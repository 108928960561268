import React, { useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { CloseIconSVG } from "../../Asset/SVG/AllSVG";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SelectParameterComponent = ({ ParaTypeList, ParaType }) => {
  const ListButton = ({ Para, SelectedPara }) => {
    return (
      <Button
        variant="contained"
        sx={{
          width: "95px",
          height: "40px",
          borderRadius: "30px",
          fontFamily: "Red Hat Display",
          display: "flex",
          textTransform: "none",
          alignItems: "center",
          justifyContent: "center",
          color: "rgba(107, 119, 129, 1)",
          transition: "border-color 0.3s",
          background:
            SelectedPara === Para
              ? "linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)"
              : "inherit",
          border: "0px",
          fontSize: "12px",
          color: SelectedPara === Para ? "white" : "rgba(128, 128, 128, 1)",
          border: SelectedPara === Para ? "none" : "inherit",
          "&:hover": {
            background:
              SelectedPara === Para
                ? "linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)"
                : "inherit",
          },
        }}
      >
        {Para}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {ParaTypeList.map((Para) => (
            <ListButton key={Para} Para={Para} SelectedPara={ParaType} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const NewProject = ({ CurrentPage }) => {
  const navigate = useNavigate();
  const PageList = ["Details", "AOI", "Parameters"];

  const OnClickClose = () => {
    const CustomToast = ({ closeToast }) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            marginBottom: "1rem",
            fontSize: "1.1rem",
            textAlign: "center",
            fontSize: "15px",
          }}
        >
          Are you sure you want to close the project?
        </p>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              padding: "0.5rem 1rem",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              fontSize: "13px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/HomePage");
              closeToast();
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
          >
            Yes
          </button>
          <button
            style={{
              cursor: "pointer",
              padding: "0.5rem 1rem",
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              borderRadius: "5px",
              fontSize: "13px",
              transition: "background-color 0.3s ease",
            }}
            onClick={closeToast}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#d32f2f")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#f44336")}
          >
            No
          </button>
        </div>
      </div>
    );

    toast.warn(<CustomToast />, {
      position: "top-center",
      autoClose: false, // Prevents auto-close to give the user time to choose
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        pl: "3rem",
        pr: "3rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color: "rgba(48, 48, 48, 0.98)",
            fontWeight: "bold",
            fontFamily: "Red Hat Display",
          }}
        >
          New Project
        </Typography>
      </Box>

      <SelectParameterComponent
        ParaTypeList={PageList}
        ParaType={CurrentPage}
      />
      <Button
        onClick={OnClickClose}
        sx={{
          width: "79px", // Adjust the width of the button as needed
          height: "36px", // Adjust the height of the button as needed
          borderRadius: "30px", // Makes it oval-shaped
          fontFamily: "Red Hat Display",
          display: "flex",
          textTransform: "none",
          alignItems: "center",
          justifyContent: "center",
          backgroundBlendMode: "overlay",
          boxShadow:
            "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
          border: "0px",
          fontSize: "11px",
          fontWeight: "bold",
          color: "rgba(206, 45, 45, 1)",
        }}
      >
        <CloseIconSVG /> <span style={{ marginLeft: "4px" }}>Close </span>
      </Button>
    </Box>
  );
};

export default NewProject;
