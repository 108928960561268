import React, { useState, useRef, useEffect } from "react";
import { Button, Box } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "../../CSS/AOI.module.css";
import "leaflet/dist/leaflet.css";
import { NavBar } from "..//Navbar/Navbar";
import "../../CSS/App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import theme from "../../Asset/Theme/theme";
import CalendarComponent from "./CalendarComponent";
import Slider from "./Slider";
import ReactDOMServer from "react-dom/server";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  LayersControl,
} from "react-leaflet";
import { BackIconSVG, CloudCoverSVG } from "../../Asset/SVG/AllSVG";
// import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import {
  graphDetail,
  RunParameterFunction,
  fetchAllResultData,
  bestWorstFarms,
  StressWiseFarms,
} from "../API/API";
import { BeatLoader } from "react-spinners";

const SetViewOnClick = ({ coords, map }) => {
  useEffect(() => {
    if (coords && map) {
      map.setView(coords, 12);
    }
  }, [coords, map]);

  return null;
};

const ParameterSelectedFunction = async (
  GeojsonData,
  selectedCrop,
  date,
  selectedParameter,
  project_id
) => {
  try {
    const response = await RunParameterFunction({
      GeojsonData,
      selectedCrop,
      date,
      selectedParameter,
      project_id,
    });

    return response;
  } catch (error) {
    console.error("Error fetching dates:", error);

    // Handle known error response from the server
    if (error.response && error.response.data && error.response.data.msg) {
      return {
        error: error.response.data.msg,
      };
    }

    return {
      error: "An unexpected error occurred. Please try again later.",
    };
  }
};

const notify = (message) => {
  toast.warn(message, {
    position: "top-center",
  });
};

export const DateSelection = () => {
  const { state } = useLocation();
  const {
    selectedCrop,
    selectedParameter,
    project,
    DateList,
    startDate,
    endDate,
    FarmList,
    Centroid,
  } = state;

  const project_id = project.id;

  const [coords, setCoords] = useState(null);

  useEffect(() => {
    setCoords([Centroid.latitude, Centroid.longitude]);
  }, []);

  const [mainSliderValue, setMainSliderValue] = useState(0);
  const filterDates = () => {
    return Object.keys(DateList).filter(
      (date) => DateList[date] <= mainSliderValue
    );
  };

  const [fetchedDateList, setFetchedDateList] = useState(filterDates());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFetchedDateList(filterDates());
  }, [mainSliderValue]);

  const [layers] = useState({
    openStreetMap: false,
    esriWorldImagery: true,
  });

  const DateFromCalendar = (dateObject) => {};

  const [formattedDate, setFormattedDate] = useState("");
  const navigate = useNavigate();

  const BackButtonToNavbar = () => {
    navigate("/ParameterSelection", {
      state: { project },
    });
  };

  const notify2 = () => {
    toast.error("An error occured, pls try again!", {
      position: "top-center",
    });
  };

  // const fetchTileLatest = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await fetch(`${API_URL}/api/load_tiff`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`, // Replace with your JWT token
  //       },
  //       body: JSON.stringify({
  //         selected_date: {},
  //       }),
  //     });

  //     let tile_url = [];
  //     const data = await response.json();
  //     tile_url = data.tile_url;
  //     return { tile_url };
  //   } catch (error) {
  //     console.error("Error loading TIFF:", error);
  //   }
  // };

  const groupedByParameter = (all_results_data) => {
    return all_results_data.reduce((acc, item) => {
      const key = item.selected_parameter;

      // If key doesn't exist, create an empty array
      if (!acc[key]) {
        acc[key] = [];
      }

      // Push the current item to the correct parameter key
      acc[key].push(item);

      return acc;
    }, {}); // <- Make sure the reduce() result is returned
  };

  const ToInsights = async () => {
    if (!formattedDate) {
      notify("Please select a date to continue!");
      return;
    }
    setLoading(true);

    try {
      notify();
      const response = await RunParameterFunction({
        GeojsonData: FarmList, // Make sure the case matches (GeojsonData vs geojsonData)
        selectedCrop: selectedCrop,
        date: formattedDate, // Your original code used 'formattedDate', but the function expects 'date'
        selectedParameter: selectedParameter,
        project_id: project_id,
      });

      let currentDayResult = response.data.status;

      if (currentDayResult == "result_already_exists") {
        notify("Result Already Exists, select a different date to continue!");
        return;
      }

      const { all_results_data, marks } = await fetchAllResultData(project_id);
      const lastResult = all_results_data[all_results_data.length - 1];

      const { top_5, worst_5, farm_health } = await bestWorstFarms({
        project_id: project.id,
        geojson_data: project.geojson,
      });

      const ParameterWiseList = groupedByParameter(all_results_data);

      // Fetch stresswise farms using lastResult values.
      let temp;
      try {
        const response = await StressWiseFarms(
          lastResult.selected_date,
          lastResult.selected_parameter
        );
        // Assume the response data is already the object you need.
        temp = response.data;
      } catch (error) {
        console.error("Error fetching stresswise farms:", error);
        // Optionally assign an empty object so further processing won't fail.
        temp = {};
      }

      // Create an array of objects that shows the inference type and count
      const StressWiseCount = Object.keys(temp).map((key) => ({
        inference: key,
        count: temp[key].length,
      }));

      navigate("/Insights", {
        state: {
          FarmList: lastResult.geojson,
          tiff_url: lastResult.tiff_url,
          excel_url: lastResult.excel_url,
          result_id: lastResult.id,
          tiff_min_max: lastResult.tiff_min_max,
          geojsonData: lastResult.geojson,
          tile_url: lastResult.tile_url,
          marks,
          project,
          selected_date: lastResult.selected_date,
          selected_parameter: lastResult.selected_parameter,
          ParameterWiseList,
          Parameter_Data: ParameterWiseList[lastResult.selected_parameter],
          project_id: project.id,
          top_5,
          worst_5,
          farm_health,
          StressWiseCount,
        },
      });
    } catch (err) {
      notify2();
      console.error("Error fetching result:", err);
    }
  };

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDateArray = startDate
    .toLocaleDateString("en-GB", options)
    .split("/");
  const formattedDateArrayend = endDate
    .toLocaleDateString("en-GB", options)
    .split("/");

  const startDateFormatted = `${formattedDateArray[0]}-${formattedDateArray[1]}-${formattedDateArray[2]}`;
  const startDateFormattedend = `${formattedDateArrayend[0]}-${formattedDateArrayend[1]}-${formattedDateArrayend[2]}`;

  const PopupContent = ({ properties }) => {
    return (
      <div>
        <div>
          <strong>Farm :</strong> {properties.Polygon_ID}
        </div>
      </div>
    );
  };

  const onFarmMouseOver = (x, layer) => {
    const popupContent = ReactDOMServer.renderToString(
      <PopupContent properties={x.properties} />
    );

    layer.bindPopup(popupContent).openPopup();
  };

  const onFarmMouseOut = (layer) => {
    layer.closePopup();
  };

  const onEachFarm = (x, layer) => {
    layer.setStyle({
      weight: 6,
      color: "#4169E1",
    });

    layer.on({
      mouseover: () => onFarmMouseOver(x, layer),
      mouseout: () => onFarmMouseOut(layer),
    });
  };

  const mapRef = useRef();
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={2000} />{" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Buttons*/}
        <NavBar
          InHomePageFlag={false}
          BackButtonInNavbar={BackButtonToNavbar}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: "3em",
            pr: "3em",
            gap: "2rem",
          }}
        >
          <Box className={styles.upper_container_dateselection}>
            <Box className={styles.lower_container_dateselection}>
              <Box
                sx={{
                  pl: "1.4rem",
                  mt: "0.5rem",
                }}
              >
                {/* <span onClick={BackToPara} style={{ cursor: "pointer" }}>
                  <BackIconSVG />
                </span> */}
              </Box>

              <Box sx={{ pl: "1.4rem", fontWeight: "bold", fontSize: "14px" }}>
                {selectedParameter} | {selectedCrop}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "15px",
                  pl: "1.4rem",
                  pr: "1.4rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  {CloudCoverSVG}{" "}
                  <span style={{ color: "transparent" }}>o</span> Cloud Cover
                </Box>
                <Box sx={{}}>
                  <Slider setMainSliderValue={setMainSliderValue} />
                </Box>
              </Box>

              <Box
                sx={{
                  pl: "1rem",
                  pr: "2rem",
                  pt: "1rem",
                }}
              >
                <CalendarComponent
                  fetchedDateList={fetchedDateList}
                  getDate={DateFromCalendar}
                  formattedDate={formattedDate}
                  setFormattedDate={setFormattedDate}
                  startDate={startDateFormatted}
                  endDate={startDateFormattedend}
                  mainSliderValue={mainSliderValue}
                />
              </Box>
            </Box>

            <Box className={styles.continue_dateselection}>
              <Button
                onClick={ToInsights}
                style={{
                  boxShadow:
                    "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
                  border: "0px", // Set border color to white
                  color: "white",
                  background:
                    " linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
                  fontSize: "12px",
                  textTransform: "none",
                  fontFamily: "Red Hat Display",
                  width: "22vw",
                  height: "6vh",
                  borderRadius: "7px",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Generating Results{" "}
                    <span style={{ marginLeft: "4px", marginTop: "0.2rem" }}>
                      {" "}
                      <BeatLoader size={4} margin={2} color="white" />
                    </span>
                  </div>
                ) : (
                  "Continue"
                )}
              </Button>
            </Box>
          </Box>

          <div className={styles.map_container}>
            <MapContainer
              center={[28.7041, 77.1025]} // long,lat of Delhi
              zoom={5}
              scrollWheelZoom={true}
              ref={mapRef}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
            >
              <SetViewOnClick coords={coords} map={mapRef.current} />
              <GeoJSON
                key={FarmList}
                data={FarmList}
                onEachFeature={onEachFarm}
              />
              <FeatureGroup></FeatureGroup>
              <LayersControl position="topright">
                <LayersControl.BaseLayer
                  checked={layers.openStreetMap}
                  name="Google Maps"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key=$`}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer
                  checked={layers.esriWorldImagery}
                  name="Satellite"
                >
                  <TileLayer
                    url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=$`}
                    // url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
            </MapContainer>
          </div>
        </Box>
      </div>
      <CssBaseline />
    </ThemeProvider>
  );
};
