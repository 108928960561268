import { create } from "zustand";
import { getUserDetails } from "../Components/API/API";

const useUserStore = create((set, get) => ({
  name: "",
  email: "",
  isFetched: false,

  initializeUserDetails: async () => {
    const token = localStorage.getItem("token"); // Get the token from localStorage
    if (token && !get().isFetched) {
      // Check if token exists and details haven't been fetched yet
      try {
        const response = await getUserDetails();
        set({
          name: response.data.name,
          email: response.data.email,
          isFetched: true,
        });
      } catch (error) {
        console.error("Error fetching user details on reload:", error);
      }
    }
  },

  setUserDetails: async () => {
    if (get().isFetched) return;

    try {
      const response = await getUserDetails();
      set({
        name: response.data.name,
        email: response.data.email,
        isFetched: true,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  },

  clearUserDetails: () => {
    set({
      name: "",
      email: "",
      isFetched: false,
    });
  },
}));

export default useUserStore;
