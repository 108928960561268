import React, { useState } from "react";
import styles from "../../CSS/Login.module.css";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import {
  AgriAiSVG,
  AtIconSVG,
  ForgotYourPassSVG,
} from "../../Asset/SVG/AllSVG";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPassword } from "../API/API";

const ForgotPass = () => {
  const navigate = useNavigate();
  const isHeightSm = useMediaQuery("(max-height:600px)");
  const isHeightMd = useMediaQuery("(max-height:800px)");
  const [submitClicked, SetSubmitClicked] = useState(false);

  const [email, setEmail] = useState("");
  const [lowerText, SetlowerText] = useState("");

  const delayerFunc = () => {
    setTimeout(() => {
      SetlowerText("");
    }, 2000);
  };

  const GoToLogin = () => {
    navigate("/");
  };

  const notify = () => {
    toast.info("Check your email to change your password", {
      position: "top-center",
      style: {
        fontSize: "14px", // Adjust font size here
      },
    });
  };

  const sequentialExecution = async () => {
    notify(); // Show toast notification
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for toast to auto-close
    GoToLogin(); // Redirect to the login page
  };

  const handleForgotPass = async (e) => {
    if (email.trim() === "") {
      SetlowerText("Please enter the email");
      delayerFunc();
      return;
    } else {
      SetSubmitClicked(true);
      try {
        // Call the forgotPassword function
        const response = await forgotPassword(email);
        if (response.data.success) {
          sequentialExecution();
        } else {
          SetlowerText(response.data.msg);
        }
        SetSubmitClicked(false);
        delayerFunc();
      } catch (error) {
        // SetlowerText("An error occurred. Please try again later.");
        // delayerFunc();
      }
    }
  };

  return (
    <div className={styles.background_container}>
      <div className={styles.Infra}>
        {" "}
        <AgriAiSVG color="white" />
      </div>
      <ToastContainer autoClose={2000} />
      <div className={styles.RightDiv}>
        <Paper
          elevation={3}
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 0.66)), linear-gradient(0deg, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0.23))",
            border: "1px solid rgba(255, 255, 255, 0.23)",
            width: "80%",
            borderRadius: "25px",
            backdropFilter: "blur(9px)",
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            paddingTop: "1em",
            alignItems: "center",
            justifyContent: "space-around",
            paddingRight: "0.9rem",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginRight: "3rem",
            }}
          >
            <Typography
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(0, 0, 0, 1)",
                fontWeight: "500",
              }}
            >
              Go back to{" "}
              <a
                onClick={GoToLogin}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  color: "rgba(210, 138, 0, 1)",
                  textDecoration: "none",
                }}
              >
                Login{" "}
                <BsArrowRight
                  style={{
                    verticalAlign: "middle",
                    marginBottom: "1px",
                    color: "black",
                    userSelect: "none",
                  }}
                />
              </a>
            </Typography>
          </div>
          <div style={{ paddingTop: "1rem" }}>
            <ForgotYourPassSVG />

            <Typography
              sx={{ fontSize: "12px", fontFamily: "Red Hat Display" }}
            >
              Enter the email address of your registered account
            </Typography>
          </div>

          <div style={{ paddingTop: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
                pt: "5rem",
              }}
            >
              <form>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <AtIconSVG />
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
          </div>

          <div
            style={{
              fontSize: "12px",
              color: lowerText ? "red" : "transparent",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {lowerText ? <div>{lowerText}</div> : <div>yess</div>}
          </div>

          <Box
            sx={{
              paddingTop: isHeightSm ? "4rem" : isHeightMd ? "6rem" : "8rem",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "1rem",
            }}
          >
            {" "}
            <Button
              onClick={handleForgotPass}
              variant="contained"
              component="div"
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(72, 87, 99, 1)",
                height: "52px",
                textTransform: "none",
                textDecoration: "none", // Add underline
                padding: "2px 165px", // Adjust padding to make the button smaller
                borderRadius: "8px",
                background: "rgba(76, 84, 209, 1)",
                color: "white",
                width: "7%",
                whiteSpace: "nowrap",
              }}
            >
              {submitClicked ? "Signing In..." : "Sign In"}
            </Button>
          </Box>

          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Red Hat Display",
              fontWeight: "bold",
              pt: "2rem",
              pb: "1rem",
            }}
          >
            Powered by ARMS 4 AI
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default ForgotPass;
