import React, { useEffect, useState } from "react";
import { Button, Box, TextField } from "@mui/material";
import "@fontsource/red-hat-display";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import styles from "./../../../CSS/Details.module.css";
import StartingDate from "./StartingDate";
import { NavBar } from "../../Navbar/Navbar";
import NewProject from "../../Navbar/NewProject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ProjectTypeIcon,
  ReportTypeIcon,
  CropTypeIcon,
  ProjectTitleIcon,
} from "../../../Asset/SVG/AllSVG";
import theme from "../../../Asset/Theme/theme";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { saveProject } from "../../API/API";

const ButtonTheme = createTheme({
  palette: {
    background: {
      md: "green", // Green for md
      lg: "pink", // Amber for lg
      xl: "violet", // Purple for xl
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const ProjectTitle = ({ onTextChange }) => {
  const [enteredText, setEnteredText] = useState("");

  const handleChange = (event) => {
    const newText = event.target.value;
    setEnteredText(newText);
    if (onTextChange) {
      onTextChange(newText); // Call the callback function with the new ProjectName
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      <ProjectTitleIcon />

      <Box>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Name Of Project"
          value={enteredText}
          onChange={handleChange}
          InputLabelProps={{
            style: {
              fontFamily: "Red Hat Display",
              color: "rgba(48, 48, 48, 0.48)",
              display: "flex",
              alignItems: "center",
              transform: enteredText
                ? "translate(0, -150%)"
                : "translate(10%, 29%)",
              opacity: enteredText ? 0 : 1, // Hide the label when text is entered
              transition: "all 0.3s ease", // Smooth transition for label
            },
            shrink: enteredText ? true : false, // Shrink label if text is entered
          }}
          sx={{
            "& .MuiInputLabel-root": {
              fontFamily: "Red Hat Display",
              transform: "translate(10%, 29%)",
              transition: "transform 0.3s ease, opacity 0.3s ease", // Smooth transition
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(0, -150%)", // Move label up when typing
              opacity: 0, // Hide the label when input is filled or focused
            },
            "& input": {
              fontFamily: "Red Hat Display",
              width: "12vw",
              height: "0.5vh",
              fontSize: "12px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              border: "2px solid rgba(219, 219, 219, 1)",
              "&:hover fieldset": {
                borderColor: "rgba(240, 240, 243, 1)",
              },
              "& fieldset": {
                borderColor: "rgba(240, 240, 243, 1)",
              },
              "&:focus-within fieldset": {
                borderColor: "white",
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

const SelectParameterComponent = ({
  ParaTypeList,
  ParaType,
  setParaType,
  IconName,
  isMultiSelect = false, // New prop to determine single/multiple selection
}) => {
  const ListButton = ({ Para, SelectedPara, onClick }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const getButtonStyles = () => {
      if (isSmallScreen) {
        return {
          width: "70px",
          height: "33px",
          fontSize: "12px",
        };
      }
      if (isMediumScreen) {
        return {
          width: "34px",
          height: "20px",
          fontSize: "9px",
        };
      } else if (isLargeScreen) {
        return {
          width: "70px",
          height: "28px",
          fontSize: "10px",
        };
      } else if (isXLargeScreen) {
        return {
          width: "90px",
          height: "32px",
          fontSize: "13px",
        };
      } else {
        return {}; // Default styles for other screen sizes
      }
    };

    const isSelected = isMultiSelect
      ? SelectedPara.includes(Para)
      : SelectedPara === Para;

    return (
      <ThemeProvider theme={ButtonTheme}>
        <Button
          variant="contained"
          onClick={() => onClick(Para)}
          sx={{
            width: "95px",
            height: "40px",
            borderRadius: "30px",
            fontFamily: "Red Hat Display",
            display: "flex",
            textTransform: "none",
            alignItems: "center",
            justifyContent: "center",
            color: isSelected ? "white" : "rgba(107, 119, 129, 1)",
            transition: "border-color 0.3s",
            background: isSelected
              ? "linear-gradient(180deg, #FFEBD3 9.62%, #FFAD5B 86.54%)"
              : "inherit",
            border: "0px",
            fontSize: "12px",
            "&:hover": {
              background: isSelected
                ? "linear-gradient(180deg, #FFEBD3 9.62%, #FFAD5B 86.54%)"
                : "inherit",
            },
            ...getButtonStyles(),
          }}
        >
          {Para}
        </Button>
      </ThemeProvider>
    );
  };

  const handleToggleFunction = (value) => {
    if (isMultiSelect) {
      setParaType((prevValue) =>
        prevValue.includes(value)
          ? prevValue.filter((v) => v !== value)
          : [...prevValue, value]
      );
    } else {
      setParaType(value);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5em" }}>
      {IconName === "Project" ? (
        <ProjectTypeIcon />
      ) : IconName === "Report" ? (
        <ReportTypeIcon />
      ) : (
        <CropTypeIcon />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1em",
          flexWrap: "wrap",
          maxHeight: ["Project", "Report"].includes(IconName)
            ? "none"
            : "150px",
          overflowY: ["Project", "Report"].includes(IconName)
            ? "none"
            : "scroll",
          padding: "0.4rem",
        }}
      >
        {ParaTypeList.map((Para) => (
          <ListButton
            key={Para}
            Para={Para}
            SelectedPara={ParaType}
            onClick={handleToggleFunction}
          />
        ))}
      </Box>
    </Box>
  );
};

export const Details = () => {
  const navigate = useNavigate();

  const BackButtonToNavbar = () => navigate("/HomePage");

  const [selectedCrop, setSelectedCrop] = useState("Rice");
  const [selectedReportType, setSelectedReportType] = useState("Weekly");
  const [selectedProjectType, setSelectedProjectType] = useState("Individual");

  const CropTypesList = [
    "Rice",
    "Barley",
    "Wheat",
    "Corn",
    "Soybean",
    "Oats",
    "Rye",
    "Sugarcane",
    "Chilli",
    "Tomato",
    "Cotton",
    "Millets",
  ];
  const ReportTypesList = ["Weekly", "Fortnightly", "Monthly", "Custom"];
  const ProjectTypesList = ["Individual", "Business"];
  const [ProjectName, setProjectName] = useState("");
  const InHomePageFlag = false;

  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const handleDateSelect = (date) => {
    setSelectedDate(dayjs(date));
  };

  const notify = (message) => {
    toast.warn(message, {
      position: "top-center",
    });
  };

  const handleContinue = async () => {
    if (!selectedDate) {
      notify("Please select a date to continue!");
      return;
    }
    if (!ProjectName) {
      notify("Please select a project name to continue!");
      return;
    }

    const response = await saveProject({
      project_title: ProjectName,
      save_project: false,
    }); // Ensure the project is saved before navigating

    if (response.data.titleExists) {
      notify(response.data.msg);
    } else {
      navigate("/AOI", {
        state: {
          ProjectTitle: ProjectName,
          ProjectType: selectedProjectType,
          SelectedCrop: selectedCrop,
          SelectedReportType: selectedReportType,
          StartingDate: selectedDate.format("DD/MM/YYYY"),
        },
      });
    }
  };

  const handleProjectName = (newText) => {
    setProjectName(newText);
  };
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={2000} />{" "}
      <NavBar
        InHomePageFlag={InHomePageFlag}
        BackButtonInNavbar={BackButtonToNavbar}
      />
      {/*New Project Text*/}
      <NewProject CurrentPage="Details" />
      <Box sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Box class={styles.main_div}>
          {/* upper div*/}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            <span
              style={{
                position: "relative",
                fontWeight: "bold",
                color: "#0F2C5A",
                fontSize: "18px",
                marginLeft: "1.2rem",
              }}
            >
              Project Details
              <span
                style={{
                  content: '""',
                  position: "absolute",
                  width: "50%",
                  height: "4px", // Adjust the height as needed
                  bottom: "-5px", // Adjust the position as needed
                  left: "0",
                  backgroundColor: "#FFA700",
                }}
              ></span>
            </span>
          </Box>

          <Box className={styles.middle_div}>
            {/* Project Title*/}
            <Box className={styles.flex_item}>
              <ProjectTitle onTextChange={handleProjectName} />
            </Box>
            {/* Project Type*/}
            <Box
              className={styles.flex_item}
              sx={{ display: "flex", paddingLeft: "6rem" }}
            >
              <SelectParameterComponent
                ParaTypeList={ProjectTypesList}
                ParaType={selectedProjectType}
                setParaType={setSelectedProjectType}
                IconName="Project"
                isMultiSelect={false}
              />
            </Box>

            {/* Plantation Date */}
            <Box
              className={styles.flex_item}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <StartingDate onDateSelect={handleDateSelect} />
            </Box>

            {/* Crop Type*/}
            <Box
              className={styles.flex_item}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              <SelectParameterComponent
                ParaTypeList={CropTypesList}
                ParaType={selectedCrop}
                setParaType={setSelectedCrop}
                IconName="Crop"
                isMultiSelect={false}
              />
            </Box>

            {/* Report Type*/}
            <Box
              className={styles.flex_item}
              sx={{ display: "flex", paddingLeft: "6rem" }}
            >
              <SelectParameterComponent
                ParaTypeList={ReportTypesList}
                ParaType={selectedReportType}
                setParaType={setSelectedReportType}
                IconName="Report"
                isMultiSelect={false}
              />
            </Box>
          </Box>

          {/* Continue div*/}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleContinue}
              sx={{
                width: "22vw", // Adjust the width of the button as needed
                height: "6vh", // Adjust the height of the button as needed
                borderRadius: "10px", // Makes it oval-shaped
                fontFamily: "Red Hat Display",
                display: "flex",
                textTransform: "none",
                alignItems: "center",
                justifyContent: "center",
                backgroundBlendMode: "overlay",
                background:
                  "linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
                boxShadow:
                  "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
                border: "0px", // Set border color to white
                color: "white",
                ransition: "border-color 0.3s",
                fontWeight: "800px",
                fontSize: "11px",
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
      <CssBaseline />
    </ThemeProvider>
  );
};
