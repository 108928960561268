import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "../../CSS/Insights.module.css";
import "leaflet/dist/leaflet.css";
import { NavBar } from "..//Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../../Asset/Theme/theme";
import { ImStack } from "react-icons/im";
import { CiWheat } from "react-icons/ci";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { Summary } from "./SummaryFolder/Summary";
import { Parameters } from "./ParametersFolder/Parameters";
import { ShutDownClients } from "../API/API";

export const Insights = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    // project,
    // FarmList,
    // tiff_url,
    // excel_url,
    // result_id,
    // tiff_min_max,
    // redsi_min_max,
    // geojson,
    // legend_quantile,
    // tiff_data,
    // marks,
    // tile_url,
    FarmList,
    tiff_url,
    excel_url,
    result_id,
    tiff_min_max,
    geojsonData,
    tile_url,
    project,
    selected_date,
    selected_parameter,
    ParameterWiseList,
    Parameter_Data,
    project_id,
    top_5,
    worst_5,
    farm_health,
    StressWiseCount,
    all_results_data,
  } = state;

  const [selectedFarmId, setSelectedFarmId] = useState(null);

  const handleSelectFarm = (farmId) => {
    setSelectedFarmId(farmId);
    parametersRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const summaryRef = useRef(null);
  const parametersRef = useRef(null);
  const [activeSection, setActiveSection] = useState("");

  const notify = () => {
    toast.loading("Shutting down clients", {
      position: "top-center",
    });
  };

  const BackButtonToNavbar = async () => {
    notify();
    await ShutDownClients(all_results_data);

    navigate("/HomePage", {});
  };
  // navigate("/DateSelection", {
  //   state: {
  //     selectedCrop,
  //     selectedParameter,
  //     project,
  //     DateList,
  //     startDate,
  //     endDate,
  //     FarmList,
  //     Centroid,
  //   },
  // });

  useEffect(() => {
    const sections = [
      { id: "summary", ref: summaryRef },
      { id: "parameters", ref: parametersRef },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5, rootMargin: "0px 0px -10% 0px" }
    );

    // Observe the sections
    sections.forEach((section) => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    // Cleanup function to unobserve
    return () => {
      sections.forEach((section) => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={2000} />

      {/* Nav bar */}

      <div className={styles.navBar}>
        <NavBar
          InHomePageFlag={false}
          BackButtonInNavbar={BackButtonToNavbar}
          ProjectName={project.project_title}
        />
        {/* <div
          style={{
            display: "flex",
            position: "fixed",
            top: "100px",
            left: "15%",
            justifyContent: "space-between",
            width: "90%",
            zIndex: 1001,
            background: "red",
          }}
        >
          <div>
            {" "}
            <span style={{ color: "#000000", fontWeight: "bold" }}>
              Welcome
            </span>
            , here is today's Statistics
          </div>
          <div></div>
        </div> */}
      </div>

      {/* Left Page bars  */}
      <div className={styles.pageBar}>
        <div className={styles.verticalMenu}>
          <div className={styles.menuItem} data-section="summary">
            <div
              className={classNames(styles.icon, {
                [styles.iconActive]: activeSection === "summary",
              })}
            >
              <CiWheat
                color={activeSection === "summary" ? "white" : "grey"}
                style={{ transform: "scale(2.1)" }}
              />
            </div>
            <span className={styles.pageText}>Summary</span>
          </div>

          <div className={styles.menuItem} data-section="parameters">
            <div
              className={classNames(styles.icon, {
                [styles.iconActive]: activeSection === "parameters",
              })}
            >
              <ImStack
                style={{ transform: "scale(1.5)" }}
                color={activeSection === "parameters" ? "white" : "grey"}
              />
            </div>
            <span className={styles.pageText}>Parameters</span>
          </div>
        </div>
      </div>

      {/* Actual section  */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Sections */}
        <div id="summary" ref={summaryRef} className={styles.section}>
          <Summary
            result_id={result_id}
            onSelectFarm={handleSelectFarm}
            project={project}
            selected_date={selected_date}
            selected_parameter={selected_parameter}
            project_id={project_id}
            top_5={top_5}
            worst_5={worst_5}
            farm_health={farm_health}
            StressWiseCount={StressWiseCount}
          />
        </div>
        <div id="parameters" ref={parametersRef} className={styles.section}>
          <Parameters
            FarmList={FarmList}
            tiff_url={tiff_url}
            excel_url={excel_url}
            tiff_min_max={tiff_min_max}
            geojsonData={geojsonData}
            selectedFarmId={selectedFarmId}
            tile_url={tile_url}
            selected_date={selected_date}
            selected_parameter={selected_parameter}
            ParameterWiseList={ParameterWiseList}
            Parameter_Data={Parameter_Data}
            project_id={project_id}
          />
        </div>
      </div>

      <CssBaseline />
    </ThemeProvider>
  );
};
