import React from "react";
import { Button, Box } from "@mui/material";
import "@fontsource/red-hat-display";
import {
  AgriAiSVG,
  MyPlansIconSVG,
  MyAccountIconSVG,
  MyProjectsIconSVG,
  BackIconSVG,
} from "../../Asset/SVG/AllSVG";
import ProfileBar from "./ProfileBar";
export const NavBar = ({ InHomePageFlag, BackButtonInNavbar, ProjectName }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        alignItems: "center",
        pr: "2.2rem",
        pl: "2.2rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.2rem",
            fontSize: "13px",
          }}
        >
          <AgriAiSVG color="#4CB963" />
          {ProjectName && (
            <span
              style={{ fontSize: "16px", fontWeight: "100", color: "grey" }}
            >
              |{" "}
            </span>
          )}
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "4px",
              fontSize: "14px",
              position: "relative",
              top: "1px",
            }}
          >
            {ProjectName}
          </span>
        </div>

        <div
          onClick={BackButtonInNavbar}
          style={{
            cursor: "pointer",
            height: "1rem",
            paddingTop: "0.4rem",
            paddingLeft: "1.4rem",
          }}
        >
          <BackIconSVG />
        </div>
      </div>
      {InHomePageFlag && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Red Hat Display",
            }}
          >
            <Button
              sx={{
                width: "140px", // Adjust the width of the button as needed
                height: "40px", // Adjust the height of the button as needed
                borderRadius: "30px", // Makes it oval-shaped
                fontFamily: "Red Hat Display",
                display: "flex",
                textTransform: "none",
                alignItems: "center",
                justifyContent: "center",
                backgroundBlendMode: "overlay",
                background:
                  " linear-gradient(180deg, #73E98C 9.62%, #53C16A 86.54%)",
                boxShadow:
                  "0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2), 2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4), -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1)", // Neomorphic shadow
                border: "0px",
                color: "white",
                transition: "border-color 0.3s",
                fontWeight: "800px",
                fontSize: "12px",
              }}
            >
              <MyProjectsIconSVG />
              <span style={{ marginLeft: "8px" }}>My Projects</span>{" "}
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MyPlansIconSVG />
            <Button
              sx={{
                fontFamily: "Red Hat Display",
                color: "rgba(128, 128, 128, 1)",
                textTransform: "none",
                fontSize: "12px",
              }}
            >
              My Plans
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MyAccountIconSVG />
            <Button
              sx={{
                fontFamily: "Red Hat Display",
                color: "rgba(128, 128, 128, 1)",
                textTransform: "none",
                fontSize: "12px",
              }}
            >
              My Account
            </Button>
          </Box>
        </div>
      )}

      <ProfileBar />
    </Box>
  );
};
