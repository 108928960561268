import React, { useState } from "react";
import styles from "../../CSS/Login.module.css";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../API/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ConfirmPasswordIconSVG,
  PasswordIconSVG,
  AgriAiSVG,
  SignInSVG,
} from "../../Asset/SVG/AllSVG";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [submitClicked, SetSubmitClicked] = useState(false);
  const [lowerText, SetlowerText] = useState("");

  const delayerFunc = () => {
    setTimeout(() => {
      SetlowerText("");
    }, "2000");
  };

  const notify = () => {
    toast.info("Password Reset Successfully!", {
      position: "top-center",
      style: {
        fontSize: "14px", // Adjust font size here
      },
    });
  };

  const GoToLogin = () => {
    navigate("/");
  };

  function validatePassword(password) {
    // Check for minimum length
    if (password.length < 8) {
      SetlowerText("Password must be at least 8 characters long.");
      delayerFunc();
      return false;
    }

    // Check for numeric digits
    if (!/\d/.test(password)) {
      SetlowerText("Password must contain at least one digit.");
      delayerFunc();
      return false;
    }

    // Check for uppercase letters
    if (!/[A-Z]/.test(password)) {
      SetlowerText("Password must contain at least one uppercase letter.");
      delayerFunc();
      return false;
    }

    // Check for lowercase letters
    if (!/[a-z]/.test(password)) {
      SetlowerText("Password must contain at least one lowercase letter.");
      delayerFunc();
      return false;
    }

    // Check for special characters
    if (!/[^A-Za-z0-9]/.test(password)) {
      SetlowerText(
        "Password must contain at least one special character (e.g., !, @, #)."
      );
      delayerFunc();
      return false;
    }

    return true;
  }

  const sequentialExecution = async () => {
    notify();
    await new Promise((resolve) => setTimeout(resolve, 3000));
    GoToLogin();
  };

  const { token } = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      return; // Stop submission if the password is not valid
    }

    if (password2.trim() === "" || password.trim() === "") {
      SetlowerText("Please fill in all fields");
      return;
    }

    if (password2 !== password) {
      SetlowerText("Passwords do not match");
      return;
    }

    SetSubmitClicked(true);

    try {
      const response = await resetPassword(token, password2);
      if (response.data.success) {
        sequentialExecution();
      } else {
        SetlowerText(response.data.msg);
      }
      SetSubmitClicked(false);
      delayerFunc();
    } catch (error) {
      SetlowerText("An error occurred. Please try again later.");
    }
  };

  const handleLogIn = () => {
    navigate("/PasswordResetSuccess");
  };

  return (
    <div className={styles.background_container}>
      <div className={styles.Infra}>
        {" "}
        <AgriAiSVG />{" "}
      </div>
      <ToastContainer autoClose={2000} />
      <div className={styles.RightDiv}>
        <Paper
          elevation={3}
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 0.66)), linear-gradient(0deg, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0.23))",
            border: "1px solid rgba(255, 255, 255, 0.23)",
            width: "400px",
            height: "24em",
            borderRadius: "25px",
            backdropFilter: "blur(9px)",
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: "2rem",
            }}
          >
            <SignInSVG />

            <div
              style={{
                fontSize: "12px",
                fontFamily: "Red Hat Display",
              }}
            >
              Already have an account ?{" "}
              <a
                onClick={GoToLogin}
                style={{
                  color: "rgba(210, 138, 0, 1)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Sign In
              </a>
            </div>
          </div>

          <div style={{ marginTop: "2rem" }}>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
              }}
            >
              <form style={{ height: "60px" }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <PasswordIconSVG />{" "}
                      <span style={{ verticalAlign: "super", color: "Red" }}>
                        *
                      </span>
                    </div>
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <Box
              sx={{
                marginTop: "5.5rem",
                display: "flex",
                justifyContent: "space-around",
                fontFamily: "Red Hat Display",
              }}
            >
              <form style={{ height: "60px" }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    userSelect: "none",
                    fontSize: "13px",
                    fontFamily: "Red Hat Display",
                    color: "rgba(128, 128, 128, 1)",
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "0.3rem",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <ConfirmPasswordIconSVG />
                        <span style={{ verticalAlign: "super", color: "Red" }}>
                          *
                        </span>
                      </div>
                    </div>

                    <IconButton
                      onClick={handleTogglePasswordVisibility2}
                      sx={{ padding: "0" }}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Typography>
                <TextField
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{
                    "& input": {
                      fontFamily: "Red Hat Display",
                      width: "260px", // Adjust the width as needed
                    },
                  }}
                  type={showPassword2 ? "text" : "password"}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />

                <button type="submit" style={{ display: "none" }} />
              </form>
            </Box>
            <div
              style={{
                fontSize: "13px",
                color: lowerText ? "red" : "transparent",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                paddingTop: "1rem",
                fontWeight: "bold",
              }}
            >
              {lowerText ? <div>{lowerText}</div> : <div>yess</div>}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5rem",
            }}
          >
            {" "}
            <Button
              onClick={handleResetPassword}
              variant="contained"
              component="div"
              sx={{
                userSelect: "none",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                color: "rgba(72, 87, 99, 1)",
                height: "52px",
                textTransform: "none",
                textDecoration: "none", // Add underline
                padding: "2px 165px", // Adjust padding to make the button smaller
                borderRadius: "8px",
                background: "rgba(76, 84, 209, 1)",
                color: "white",
                width: "7%",
                whiteSpace: "nowrap",
              }}
            >
              {submitClicked ? "Resetting password..." : "Reset Password"}
            </Button>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Red Hat Display",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Powered by ARMS 4 AI
            </Typography>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ResetPassword;
