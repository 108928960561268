import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import "./../../../CSS/ParameterType.css";
import { BiRupee } from "react-icons/bi";
import { Description } from "@mui/icons-material";

const ParameterType = ({ CropName, Price, onClick, Description }) => {
  const defaultBorderColor = "rgba(219, 219, 219, 1)";
  const activeBorderColor = "rgba(76, 185, 99, 1)";

  const [borderColor, setBorderColor] = useState(defaultBorderColor);

  // Use useCallback to memoize the function and prevent unnecessary re-renders
  const handleBoxClick = useCallback(() => {
    // Update the border color only if it actually needs to change
    setBorderColor((prevColor) => {
      const newColor =
        prevColor === defaultBorderColor
          ? activeBorderColor
          : defaultBorderColor;
      if (newColor !== prevColor) {
        return newColor;
      }
      return prevColor; // No change, so no re-render
    });

    // Call the parent onClick handler if provided
    if (onClick) {
      onClick();
    }
  }, [onClick, defaultBorderColor, activeBorderColor]);

  const boxStyle = {
    display: "flex",
    borderColor: borderColor,
    borderWidth: "1.2px",
    borderStyle: "solid",
    borderRadius: "10px",
    cursor: "pointer",
  };

  return (
    <Box sx={boxStyle} onClick={handleBoxClick}>
      <Box className="Parameter-box">
        <Box className="Crop-Name">
          <Box sx={{ color: "rgba(0, 0, 0, 1)", fontWeight: "bold" }}>
            {CropName}
          </Box>
          <Box sx={{ pt: "0.5rem", color: "black" }}>{Description}</Box>
        </Box>
        {/* <Box className="Price">
          <BiRupee style={{ verticalAlign: "-2px" }} />
          {Price}
        </Box> */}
      </Box>
    </Box>
  );
};

export { ParameterType };
