import axios from "axios";

const API_URL = "https://cloud.arms4ai.com";

const register = (name, email, password) => {
  return axios.post(`${API_URL}/register`, {
    name,
    email,
    password,
  });
};

const confirmEmail = (token) => {
  return axios.get(`${API_URL}/confirm/${token}`);
};

const LoginEndpoint = (email, password) => {
  return axios.post(
    `${API_URL}/login`,
    {
      email,
      password,
    },
    {
      withCredentials: true, // This ensures that cookies are sent with the request
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const logout = () => {
  const token = localStorage.getItem("token");
  return axios.post(
    `${API_URL}/logout`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const getProjects = () => {
  const token = localStorage.getItem("token");
  return axios.get(`${API_URL}/projects`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const saveProject = (projectData) => {
  const token = localStorage.getItem("token");
  return axios.post(`${API_URL}/projects`, projectData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const graphDetail = ({ unique_farm_id, date, parameter }) => {
  const data = {
    unique_farm_id,
    date,
    parameter,
  };

  const token = localStorage.getItem("token");
  return axios.post(`${API_URL}/fetch_graph_details`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const fetchInferenceData = (data) => {
  const token = localStorage.getItem("token");
  return axios.post(`${API_URL}/get_inference_data`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const forgotPassword = (email) => {
  return axios.post(`${API_URL}/forgot_password`, {
    email,
  });
};

const resetPassword = (token, newPassword) => {
  return axios.post(`${API_URL}/reset_password/${token}`, {
    new_password: newPassword,
  });
};

const getUserDetails = () => {
  const token = localStorage.getItem("token");
  return axios.get(`${API_URL}/user_details`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const fetchDates = ({
  GeojsonData,
  startDate,
  endDate,
  selectedParameter,
  selectedFarmIds,
}) => {
  const token = localStorage.getItem("token");
  const data = {
    GeojsonData,
    startDate,
    endDate,
    selectedParameter,
    selectedFarmIds,
  };

  return axios.post(`${API_URL}/fetch_dates`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

// this and function below
const RunParameterFunction = async ({
  GeojsonData,
  selectedCrop,
  date,
  selectedParameter,
  project_id,
}) => {
  const token = localStorage.getItem("token");
  const data = {
    GeojsonData,
    selectedCrop,
    date,
    selectedParameter,
    project_id,
  };

  if (selectedParameter == "Water Stress")
    return axios.post(`${API_URL}/water_stress_api`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  else if (selectedParameter == "Crop Growth")
    return axios.post(`${API_URL}/crop_growth_api`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

  return axios.post(`${API_URL}/crop_stress_api`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const StressWiseFarms = async (currentDate, selectedParam) => {
  const token = localStorage.getItem("token");
  const data = {
    currentDate,
    selectedParam,
  };
  return axios.post(`${API_URL}/get_stresswise_farms`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const fetchAllResultData = async (project_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/send_all_result_data`,
      { project_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const all_results_data = response.data || [];

    return { all_results_data };
  } catch (error) {
    console.error("Error fetching TIFF data:", error);
    return { all_results_data: [] }; // Return empty arrays instead of null
  }
};

const ShutDownClients = (data) => {
  const token = localStorage.getItem("token");
  console.log(data, " in shutdown");
  return axios.post(`${API_URL}/close_clients`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const datesAnalysed = (data) => {
  const token = localStorage.getItem("token");
  return axios.post(`${API_URL}/get_dates_analysed`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const bestWorstFarms = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(`${API_URL}/get_best_worst_farms`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // Assume backend returns an array with one object.
    // Return that object directly.
    console.log(response.data);
    return response.data.best_worst_list[0] || {};
  } catch (error) {
    console.error("Error in bestWorstFarms:", error);
    throw error;
  }
};

export {
  register,
  confirmEmail,
  LoginEndpoint,
  logout,
  getProjects,
  saveProject,
  forgotPassword,
  resetPassword,
  getUserDetails,
  fetchDates, // Exporting the new fetchDates function
  RunParameterFunction,
  graphDetail,
  fetchAllResultData,
  fetchInferenceData,
  API_URL,
  datesAnalysed,
  StressWiseFarms,
  bestWorstFarms,
  ShutDownClients,
};
