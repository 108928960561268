import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import styles from "../../CSS/HomePage.module.css";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div className={styles.loader_container}>
        <div className={styles.loader}></div>
        <div className={styles.loader_text}>Establishing Session...</div>
      </div>
    );
  }

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
